<template>
  <el-dialog title="报价设置（华为）" :visible.sync="visible" :close-on-click-modal="false" width="680px">
    <div class="unit_info">
      <div class="unit_info_title">
        同串号重复订单报价设置
      </div>
      <div class="flex mt-20">
        <div style="margin-right: 10px">开启同串号重复订单系统自动报价：</div>
        <el-switch
          v-model="isImeiAutoQuote"
          active-text="开"
          inactive-text="关"
        >
        </el-switch>
      </div>
      <div class="flex mt-20" v-if="isImeiAutoQuote">
        <div style="margin-right: 10px">同一串号重复第</div>
        <el-input-number
          size="small"
          v-model="imeiCount"
          :min="1"
          :max="2"
          @keydown.native="channelInputLimit"
        ></el-input-number>
        <div style="margin-left: 10px">次系统自动报价</div>
      </div>
      <div class="description-box mt-20">
        <p>同串号自动报价需满足以下条件：</p>
        <p>1.同串号重评订单与原订单为同一个分类</p>
        <p>2.重评订单有预估价</p>
        <p>3.重评后订单预估价不变或变高</p>
        <p>4.重评后订单串号为15位</p>
      </div>
    </div>
    <div class="flex mt-20">
      <div style="width: 90px">动态口令：</div>
      <el-input
        v-model.trim="command"
        placeholder="请输入动态口令"
        @input="(val) => (command = val.replace(/[^\d]/g, ''))"
      ></el-input>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import _api from "@/utils/request";
export default {
  data() {
    return {
      visible: false,
      isImeiAutoQuote: false,
      imeiCount: '',
      command: ''
    }
  },
  methods: {
    open() {
      this.command = ''
      this.getData()
      this.visible = true
    },
    getData() {
      _api.findHiOrderSameImeiQuote().then(res => {
        const { isImeiAutoQuote, imeiCount } = res.data
        this.isImeiAutoQuote = isImeiAutoQuote
        this.imeiCount = imeiCount
      })
    },
    confirm() {
      _api.updateHiOrderSameImeiQuote({
        imeiCount: this.imeiCount,
        isImeiAutoQuote: this.isImeiAutoQuote,
        command: this.command
      }).then(() => {
        this.$message.success('操作成功！')
        this.visible = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
}
.mt-20 {
  margin-top: 20px;
}
.unit_info {
  margin-bottom: 40px;

  .unit_info_title {
    position: relative;
    font-size: 16px;
    font-weight: bold;
    color: #0981ff;
    padding-left: 15px;
  }

  .unit_info_title::before {
    position: absolute;
    content: " ";
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    width: 3px;
    height: 21px;
    background: #0981ff;
    border-radius: 2px;
  }
}

.description-box{
  padding: 15px 10px;
  border-radius: 10px;
  background: #eeeeee;
}
</style>