<template>
  <div>
    <el-dialog
      title="限时报价设置"
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="800px"
    >
      <el-form>
        <el-form-item label="选择商家：">
          {{ dialogType == 'batch' ? `已选择${merchantIdList.length}个商家` : row.merchantName }}
        </el-form-item>
        <el-form-item label="是否开启限时报价">
          <el-switch v-model="isLimitQuote" active-text="开" inactive-text="关" inactive-color="#b6b9d0" />
        </el-form-item>
        <el-form-item label="限时抢单规则">
          <el-button type="primary" icon="el-icon-plus" style="float:right" @click="$refs['timeQuoteRuleDia'].open()">添加规则</el-button>
        </el-form-item>
      </el-form>
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        isIndex
        :isPagination="false"
        :columns="columns"
        :data="list"
      >
        <el-table-column label="时间段" slot="limitExclusiveStart" align="center">
          <template slot-scope="{ row }">
            {{ row.limitExclusiveStart }} - {{ row.limitExclusiveEnd }}
          </template>
        </el-table-column>
        <el-table-column label="限时抢单时长" slot="limitExclusiveStart" align="center">
          <template slot-scope="{ row }">
            {{ row.limitExclusiveLength }}分钟
          </template>
        </el-table-column>
        <el-table-column label="开启限时抢单" slot="isLimitQuote" align="center">
          <template slot-scope="{ row }">
            <el-switch v-model="row.isLimitQuote" active-text="开" inactive-text="关" inactive-color="#b6b9d0" />
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="operation" align="center">
          <template slot-scope="{ row, $index }">
            <el-link type="primary" :underline="false" style="margin-right: 10px" @click="$refs['timeQuoteRuleDia'].open(row)">编辑</el-link>
            <el-link type="danger" :underline="false" @click="delHandler($index)">删除</el-link>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="visible = false">取 消</el-button>
        <el-button :loading="confirmLoading" size="small" type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
    <TimeQuoteRuleDia ref="timeQuoteRuleDia" :ruleList="list" @confirm="ruleHandler" />
  </div>
</template>

<script>
import _api from "@/utils/request";
import TimeQuoteRuleDia from './TimeQuoteRuleDia.vue';
export default {
  components: { TimeQuoteRuleDia },
  name: "TimeQuoteSettingDia",
  filters: {
    optionsFilter(v, options) {
      return options.find(item => item.value == v)?.label
    }
  },
  data() {
    return {
      dialogType: '',
      visible: false,
      loading: false,
      confirmLoading: false,
      
      row: {
        merchantName: '',
      },
      list: [],
      columns: [
        { slotName: "limitExclusiveStart" },
        { slotName: "limitExclusiveLength" },
        { slotName: "isLimitQuote" },
        { slotName: "operation" },
      ],
      merchantIdList: [],
      isLimitQuote: false,
      deleteRules: []
    };
  },
  methods: {
    open(type, v) {
      this.dialogType = type
      if (type == 'batch') {
        this.merchantIdList = v
        this.isLimitQuote = false
        this.list = []
      } else {
        this.row = v
        this.merchantIdList = [v.merchantId]
        this.getExclusiveConfig()
      }
      this.deleteRules = []
      this.visible = true
    },
    getExclusiveConfig() {
      _api.getExclusiveConfig({
        merchantId: this.row.merchantId
      }).then(res => {
        this.isLimitQuote = res.data.isLimitQuote
        this.list = res.data.rules
      })
    },
    ruleHandler(type, data) {
      if (type == 'edit') {
        const { id, _t } = data
        let i = -1
        if (id) {
          i = this.list.findIndex((item) => item.id === id)
        } else {
          i = this.list.findIndex((item) => item._t === _t)
        }
        if (i !== -1) {
          this.$set(this.list, i, data)
        }
      } else {
        this.list.push({
          ...data,
          _t: Math.random()
        })
      }
    },
    delHandler(index) {
      const item = this.list[index]
      if (item.id) this.deleteRules.push(item.id)
      this.list.splice(index, 1)
    },
    confirm() {
      const timeArr = this.list
        .map((item) => [item.limitExclusiveStart, item.limitExclusiveEnd])
        .sort((a, b) => a[0] < b[0] ? -1 : 1)
      for (let i = 0; i < timeArr.length; i++) {
        for (let j = i + 1; j < timeArr.length; j++) {
          if (timeArr[i][0] < timeArr[j][0] && timeArr[j][0] < timeArr[i][1]) {
            this.$message.error('时间区间有重复！')
            return
          }
        }
      }
      this.confirmLoading = true
      _api.saveExclusiveConfig({
        deleteRules: this.deleteRules,
        isLimitQuote: this.isLimitQuote,
        merchantIdList: this.merchantIdList,
        rules: this.list,
        saveType: this.dialogType == 'batch' ? '01' : '00'
      }).then(res => {
        this.$message.success('操作成功！')
        this.visible = false
        this.$emit('confirm')
      }).finally(() => {
        this.confirmLoading = false
      })
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}
/*打开时文字位置设置*/
::v-deep .el-switch__label--right {
  z-index: 1;
  right: 20px;
}
/*关闭时文字位置设置*/
::v-deep .el-switch__label--left {
  z-index: 1;
  left: 20px;
}
/*显示文字*/
::v-deep .el-switch__label.is-active {
  display: block;
}
::v-deep .el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 55px;
}
</style>