<template>
  <div class="merchant-list">
    <GlobalInfoBar
        title="商家列表"
        content="管理人员可在后台添加回收商家、查看商家详细资料及禁用/启用商家等，申请入驻审核通过的商家将自动进入"
    />
    <GlobalChunk
        icon="search"
        title="筛选查询"
    >

      <div slot="filter">
        <Form />
      </div>

      <Table />
    </GlobalChunk>
  </div>
</template>

<script>
import Form from "./Form"
import Table from "./Table"
export default {
  name: 'merchant-list',
  components: {Form, Table},
  data(){
    return{
    }
  },
  methods:{
  },
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.merchant-list{
}
</style>
