<template>
  <dialog-tbale
    title="启用停用记录"
    :show="show"
    :data="data"
    :columns="columns"
    :loading="loading"
    isIndex
    :currentPage="pageNum"
    :pageSize="pageSize"
    :total="total"
    :width="1000"
    @onClose="show = false"
    @handleCurrentChange="getData" :maxHeight="540"
  >
    <el-table-column slot="optAdminName" label="操作人" prop="optAdminName" align="center">
      <template slot-scope="{ row }">
        {{ row.optAdminName || '系统' }}
      </template>
    </el-table-column>
    <el-table-column slot="actionType" label="操作" prop="actionType" align="center">
      <template slot-scope="{ row }">
        {{ row.actionType | optionsFilter(actionTypes)}}
      </template>
    </el-table-column>
  </dialog-tbale>
</template>


<script>
import _api from "@/utils/request";
import DialogTbale from '../../../../../components/global/components/DialogTbale.vue'
export default {
  components: { DialogTbale },
  name: 'PushRecordDia',
  filters: {
    optionsFilter(v, options) {
      return options.find(item => item.value == v)?.label
    }
  },
  data() {
    return {
      show: false,
      loading: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      data: [],
      columns: [
        { slotName: "actionType" },
        { slotName: "optAdminName" },
        { label: "时间", prop: "createTime" },
        { label: "操作原因", prop: "cause" }
      ],
      row: {},
      actionTypes: [
        { label: '开始推送', value: '01' },
        { label: '停止推送', value: '02' },
      ]
    }
  },
  methods: {
    open(row) {
      this.row = row
      this.getData()
      this.show = true
    },
    getData(v) {
      const { row, pageSize } = this
      this.loading = true
      _api.openCloseRecords({
        // cause,
        // isWechatPush: !isWechatPush,
        merchantId: row.merchantId,
        merchantStaffId: row.staffId,
        pageNum: v || 1,
        pageSize
      }).then((res) => {
        const { records, total, current } = res.data
        this.data = records,
        this.total = total
        this.pageNum = current
      }).finally(() => {
        this.loading = false
      })
    },
    filterHandler(value, row, column) {
      console.log(value, row, column)
      const property = column['property'];
      return row[property] === value;
    }
  }
}
</script>