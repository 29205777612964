import Vue from 'vue'
import MessageBox from './MessageBox.vue'

// const defaultData = {
//   visible: false,
//   title: '',
//   width: '450px',
//   desc: '',
//   message: '',

//   showCancelButton: true,
//   showConfirmButton: true,
//   cancelButtonText: '取 消',
//   confirmButtonText: '确 定',
// }

const MsgBoxConstructor = Vue.extend(MessageBox)

/**
 * 
 * @param {object} options 
 * @param {string} options.title 弹窗标题
 * @param {string} options.width 弹窗宽度
 * @param {string} options.desc 说明文字，红色
 * @param {string} options.message 消息文字，支持html
 * @param {boolean} options.showCancelButton 是否显示取消按钮
 * @param {boolean} options.showConfirmButton 是否显示确认按钮
 * @param {string} options.cancelButtonText 取消按钮文字
 * @param {string} options.confirmButtonText 确认按钮文字
 * @param {function} options.confirm 回调函数
 */
export const confirm = (options) => {
  const el = document.createElement('div')
  const instance = new MsgBoxConstructor({
    el,
    // data: { ...defaultData, ...options },
    data: { ...options },
    methods: {
      closed() {
        instance.$el.remove()
      }
    }
  });
  document.body.appendChild(instance.$el)
  Vue.nextTick(() => {
    instance.open()
  })
}

// /**
//  * 
//  * @param {object} options 
//  * @param {string} options.title 弹窗标题
//  * @param {string} options.width 弹窗宽度
//  * @param {string} options.desc 说明文字，红色
//  * @param {string} options.message 消息文字，支持html
//  * @param {boolean} options.showCancelButton 是否显示取消按钮
//  * @param {boolean} options.showConfirmButton 是否显示确认按钮
//  * @param {string} options.cancelButtonText 取消按钮文字
//  * @param {string} options.confirmButtonText 确认按钮文字
//  * @param {string} options.label label
//  * @param {string} options.placeholder placeholder
//  * @param {function} options.confirm 回调函数
//  */
// export const prompt = (options) => {
//   const el = document.createElement('div')
//   const instance = new MsgBoxConstructor({
//     el,
//     // data: { ...defaultData, ...options },
//     data: { ...options, type: 'prompt' },
//     methods: {
//       closed() {
//         instance.$el.remove()
//       }
//     }
//   });
//   document.body.appendChild(instance.$el)
//   Vue.nextTick(() => {
//     instance.open()
//   })
// }