<template>
  <div class="GlobalTable">
    <GlobalTable ref="GlobalTable" v-loading="loading" :columns="tableColumns" :data="this.seachDataList"
      :currentPage="page.pageNum" :total="this.page.total" @handleCurrentChange="handleCurrentChange">
      <el-table-column label="商家名称" slot="merchantName" align="center">
        <template slot-scope="{ row }">
          <div>
            <span v-if="row.channel == '01'" class="channeltype1">后台</span>
            <span v-if="row.channel == '02'" class="channeltype2">前端</span>{{ row.merchantName }}
          </div>
          <div>{{ row.isNiceMerchant ? "(靓机回收商)" : "" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="商家类型" slot="merchantEnterType" align="center">
        <template slot-scope="{ row }">
          <div>
            <span v-if="row.merchantEnterType == '00'">正式商家</span>
            <span v-if="row.merchantEnterType == '01'">外部商家</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="账户类型" slot="accountType" align="center" width="130px">
        <template slot-scope="{ row }">
          <template v-if="row.accountType == '01'">
            <p>从账户</p>
            <el-link type="primary" @click="$refs['masterAccountDia'].open(row)">查看主账户</el-link>
          </template>
          <template v-else>
            <p>主账户</p>
            <template v-if="row.isSlaveAccount">
              <p v-if="row.slaveAccountNum">
                关联
                <span style="color:#FFAF29;text-decoration: underline;cursor: pointer" @click="$refs['subAccountDia'].open(row)">{{ row.slaveAccountNum }}</span>
                个从账户
              </p>
              <p v-else>未关联从账户</p>
            </template>
            <p v-else>暂未启用从账户管理</p>
            <el-link v-if="row.isSlaveAccount" type="warning" @click="$refs['addSubAccountDia'].open(row)">添加</el-link>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="联系人" slot="people" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.contactName }}</div>
          <div>{{ row.contactPhone }}</div>
        </template>
      </el-table-column>
      <el-table-column label="商家地址" slot="operationAddress" align="center">
        <template slot-scope="{ row }">
          <el-tooltip :content="row.fullAddress" placement="top">
            <p style="
                                          display: -webkit-box;
                                          -webkit-box-orient: vertical;
                                          -webkit-line-clamp: 3;
                                          overflow: hidden;
                                        ">
              {{ row.fullAddress }}
            </p>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- <el-table-column label="省市区" slot="operation" align="center">
        <template slot-scope="{ row }">
          <span>{{row.province}}{{row.city}}{{row.region}}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="员工数量" slot="operation" align="center">
        <template slot-scope="{ row }">
          <span @click="DialoghandleBtn(row, 'staffCount')" class="tableBtn"><span
              style="border-bottom: 1px solid #0981ff">{{
                row.staffCount
              }}</span></span>
        </template>
      </el-table-column>
      <el-table-column label="合作中商家" slot="operation" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.merchantEnterType == '00'" @click="DialoghandleBtn(row, 'business')" class="tableBtn"><span
              style="border-bottom: 1px solid #0981ff">{{
                row.businessPartnerIngCount
              }}</span></span>
          <span v-else @click="DialoghandleOutBtn(row)" class="tableBtn"><span style="border-bottom: 1px solid #0981ff">{{
            row.businessPartnerIngCount
          }}</span></span>
          <p v-if="!row.isMatrix && row.merchantEnterType == '00'&&!row.isNiceMerchant" @click="appauxiliary(row)"
            style="color:#FFAF29;cursor: pointer;text-decoration:underline">添加</p>
        </template>
      </el-table-column>
      <el-table-column label="累计成交订单" slot="operation" align="center">
        <template slot-scope="{ row }">
          <span @click="problemClick(row)" class="tableBtn"><span
              style="border-bottom: 1px solid #0981ff">{{
                row.orderCount
              }}</span></span>
        </template>
      </el-table-column>
      <el-table-column label="收货次数" slot="operation" align="center">
        <template slot-scope="{ row }">
          <div class="account">
            <div>{{ row.remainScanTimes }}</div>
            <div @click="DialoghandleBtn(row, 'remainScanTimes')" class="tableBtn">
              <span style="border-bottom: 1px solid #0981ff">明细</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="物流设置" slot="operation" align="center">
        <template slot-scope="{ row }">
          <div style="color: #0981ff; cursor: pointer" v-if="row.isLogisticsConfig" @click="seeLogisticsSet(row)">
            <span style="border-bottom: 1px solid #0981ff">查看设置</span>
          </div>
          <span v-else>未启用物流</span>
        </template>
      </el-table-column>
      <el-table-column label="报价设置" slot="operation" align="center">
        <template slot-scope="{ row }">
          <div style="color: #0981ff; cursor: pointer" @click="seeSetWaitQuoteJump(row)">
            <span style="border-bottom: 1px solid #0981ff">设置</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="是否推荐" slot="operation" align="center">
        <!-- <template slot-scope="{ row }">
          <el-switch inactive-color="#bebebe" @change="handleSwitch(row)" v-model="row.isRecommend"
            :disabled="row.isNiceMerchant == true ? true : false"></el-switch>
        </template> -->
        <template slot-scope="{ row }">
          <el-switch inactive-color="#bebebe" @change="handleSwitch(row)" v-model="row.isRecommend"
            :disabled="row.isNiceMerchant == true || row.merchantEnterType == '01' ? true : false"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="回收商管理后台" slot="operation" align="center" width="90">
        <!-- <template slot-scope="{ row }">
          <el-switch inactive-color="#bebebe" @change="handleRecyclerSwitch(row)" v-model="row.isOpenPc"></el-switch>
          <div class="tableBtn" @click="branchAcc(row)">
            <span style="border-bottom: 1px solid #0981ff">
              {{ row.isAssignAccount ? "查看账号" : "分配账号" }}</span>
          </div>
        </template> -->
        <template slot-scope="{ row }">
          <el-switch inactive-color="#bebebe" :disabled="row.merchantEnterType == '01'"
            @change="handleRecyclerSwitch(row)" v-model="row.isOpenPc"></el-switch>
          <div v-if="row.merchantEnterType != '01'" class="tableBtn" @click="branchAcc(row)">
            <span style="border-bottom: 1px solid #0981ff">
              {{ row.isAssignAccount ? "查看账号" : "分配账号" }}</span>
          </div>
          <div v-else>外部商家，无法启用</div>
        </template>
      </el-table-column>
      <!-- 是否可联系店员 -->
      <!-- <el-table-column label="是否可联系店员" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-switch v-if="row.isNiceMerchant" :active-color="'#7E7E7E'" key="lian" @change="contactStaffSwitch(row)"
            v-model="row.isContact" disabled></el-switch>
          <el-switch key="no" v-else @change="contactStaffSwitch(row)" v-model="row.isContact"></el-switch>
        </template>
      </el-table-column> -->
      <el-table-column label="是否标记矩阵商户" slot="operation" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.isMatrix" style="color: #FF0000">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column label="线上结算渠道服务费" slot="operation" align="center" width="90">
        <template slot-scope="{ row }">
          <div class="service_charge">
            <el-switch v-if="row.merchantEnterType == '00'" v-model="row.matchServerFeeOnline" active-text="开"
              inactive-text="关" inactive-color="#b6b9d0" @change="(e) => myMethodsSwitch(e, row)">
            </el-switch>
            <div v-else>外部商家，无法启用</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="报价配置" slot="operation" align="center">
        <template slot-scope="{ row }">
          <div v-if="!row.isMatrix" style="color: #0981ff; cursor: pointer" @click="setQuotecategory(row)">
            <span style="border-bottom: 1px solid #0981ff">设置</span>
          </div>
          <div v-if="row.isMatrix" style="cursor: pointer;">
            <span style="border-bottom: 1px solid #333333">设置</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="报价填写预估机况" slot="operation" align="center" width="90">
        <template slot-scope="{ row }">
            <el-switch  v-model="row.isHealthCodeCus"  @change="estimatedMethodsSwitch($event, row)">
            </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="回收额度配置" slot="operation" align="center" width="90">
        <template slot-scope="{ row }">
            <el-switch  v-model="row.isAccountLimit"  @change="AccountLimitcont(row)">
            </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="同步erp数据" slot="operation" align="center" width="90">
        <template slot-scope="{ row }">
            <el-switch :disabled="row.isMatrix || row.merchantEnterType == '01'||row.isNiceMerchant"  v-model="row.isSyncErp"  @change="ErpMethodsSwitch($event, row)">
            </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="备注说明" width="120rpx" slot="operation" align="center">
        <!-- slot-scope="{ row }" -->
        <template slot-scope="{ row }">
          <el-tooltip class="item" effect="dark" :content="row.masterNote" placement="top">
            <div class="Remarks">
              {{ row.masterNote || "--" }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column width="220" label="操作" fixed="right" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" @click="handleBtn(row, 'edit')" style="margin-bottom: 6px">编辑
          </el-button>
          <!-- <el-button v-if="!row.masterNote" style="margin-left: 6px;margin-bottom: 6px;" size="mini" type="warning"
            @click="Remarks(row)">
            添加备注
          </el-button>
          <el-button v-else size="mini" style="margin-left: 6px;margin-bottom: 6px;" type="warning"
            @click="Remarkstwo(row)">修改备注
          </el-button>
          <span v-if="row.isEnable === true" class="handleClass" style="margin-bottom: 6px">
            <el-button size="mini" type="danger" @click="handleBtn(row, 'danger')">禁用
            </el-button>
          </span>
          <span v-if="row.isEnable === false" class="handleClass">
            <el-button size="mini" type="success" @click="handleBtn(row, 'success')">启用
            </el-button>
          </span>
          <el-button v-if="!row.isMatrix && row.merchantEnterType == '00'" style="margin-top: 6px;margin-left: 6px"
            size="mini" type="success" @click="associationStore(row)">
            标记矩阵商户
          </el-button> -->
          <el-button v-if="row.isSlaveAccount" type="danger" size="mini" @click="changeSlave(row, false)">关闭从账户管理</el-button>
          <el-button v-else type="success" :disabled="row.accountType == '01' || row.merchantEnterType == '01' || row.isNiceMerchant || row.isMatrix" size="mini" @click="changeSlave(row, true)">启用从账户管理</el-button>
          <el-dropdown>
            <el-button size="mini" type="primary">
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <span v-if="row.isEnable === true" @click="handleBtn(row, 'danger')">
                <el-dropdown-item>禁用</el-dropdown-item>
              </span>
              <span v-if="row.isEnable === false" @click="handleBtn(row, 'success')">
                <el-dropdown-item>启用</el-dropdown-item>
              </span>
              <span v-if="!row.masterNote" @click="Remarks(row)">
                <el-dropdown-item>添加备注</el-dropdown-item>
              </span>
              <span v-else @click="Remarkstwo(row)">
                <el-dropdown-item>修改备注</el-dropdown-item>
              </span>
              <span v-if="!row.isMatrix && row.merchantEnterType == '00'" @click="associationStore(row)">
                <el-dropdown-item>标记矩阵商户</el-dropdown-item>
              </span>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 添加从账户 -->
    <AddSubAccountDia ref="addSubAccountDia" @confirm="handleCurrentChange(pageNum)" />
    <SubAccountDia ref="subAccountDia" @close="handleCurrentChange(pageNum)" />
    <MasterAccountDia ref="masterAccountDia" />
    <!-- 批量创建合作 -->
    <el-dialog title="批量创建合作" :visible.sync="automatedDloding" :close-on-click-modal="false" width="1600px"
      @closed="automatedDloding = false; selectedStoreList = []">
      <div style="display:flex;justify-content: space-between;">
        <div style="width: 850px;margin-right:20px">
          <el-form :inline="true" :model="createInline" class="demo-form-inline">
            <el-form-item label="门店商家">
              <el-select size="small" v-model="createInline.companyId" clearable filterable placeholder="请选择">
                <el-option v-for="item in compydataList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="省市查询">
              <el-select size="small" v-model="createInline.provinceId" @change="selectProvince" clearable filterable
                placeholder="请选择省">
                <el-option v-for="item in ProvinceList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-select size="small" :disabled="!createInline.provinceId" v-model="createInline.cityId" clearable
                filterable placeholder="请选择市">
                <el-option v-for="item in cityList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否添加矩阵">
              <el-select size="small" v-model="matrixValue" clearable filterable placeholder="请选择">
                <el-option v-for="item in AdditivematrixOption" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="报价矩阵">
              <el-select size="small" v-model="matrixId" clearable filterable placeholder="请选择">
                <el-option v-for="item in juMatrixList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" @click="serchvoluntarilySubmit">查询</el-button>
            </el-form-item>
          </el-form>
          <el-table ref="addtipleTable" :data="createStoreList" tooltip-effect="dark" border max-height="530"
            style="width: 100%" @selection-change="zdmodelleSelect">
            <el-table-column type="selection" align="center"> </el-table-column>
            <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
            <el-table-column label="门店商名称" align="center">
              <template slot-scope="{ row }">{{ row.companyName || "--" }}</template>
            </el-table-column>
            <el-table-column label="创建人" align="center">
              <template slot-scope="{ row }">{{ row.contact || "--" }}</template>
            </el-table-column>
            <el-table-column label="联系电话" align="center">
              <template slot-scope="{ row }">{{ row.phone || "--" }}</template>
            </el-table-column>
            <el-table-column label="省市区" align="center">
              <template slot-scope="{ row }">
                <el-tooltip :content="row.province + row.city + row.region + row.address" placement="top">
                  <p class="clamp2">
                    {{ row.province + row.city + row.region + row.address }}
                  </p>
                </el-tooltip>

              </template>
            </el-table-column>
            <el-table-column label="当前报价矩阵" align="center">
              <template slot-scope="{ row }">{{ row.matrixName || "--" }}</template>
            </el-table-column>
            <!-- 回收商在矩阵的 -->
            <el-table-column label="回收商在矩阵报价状态" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.merchantInMatrixState != '02'">
                  {{ row.merchantInMatrixState == '01' ? '未添加矩阵' : '关闭报价' }}
                </span>
                <div v-else style="color:red">
                  <span>合作中</span>
                  <p>(可能重复报价)</p>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination style="text-align: right; margin-top: 20px" @current-change="storeCurrentChange"
            :current-page="currentPage4" layout="total,  prev, pager, next, jumper" :total="faultTypeList.total"
            :background="true">
          </el-pagination>
        </div>
        <div style="width: 700px;">
          <p style="margin-bottom:20px">已选商户({{ selectedStoreList.length }})</p>
          <el-table ref="addtipleTable" :data="selectedStoreList" tooltip-effect="dark" border max-height="640"
            style="width: 100%">
            <el-table-column type="index" align="center" label="序号" width="50">
            </el-table-column>
            <el-table-column label="门店商名称" align="center">
              <template slot-scope="{ row }">{{ row.companyName || "--" }}</template>
            </el-table-column>
            <el-table-column label="是否添加分转" align="center">
              <template slot-scope="{ row }">
                <el-select @change="AddDptmfenzhuan($event, row)" v-model="row.isAddDptm" placeholder="请选择">
                  <el-option v-for="item in  addspinList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column align="center" label="分转仓库" width="170px">
              <template slot-scope="{row}">
                <el-select clearable :disabled="!row.isAddDptm" v-model="row.warehouseId" placeholder="请选择分转仓">
                  <el-option v-for="item in entrepotList" :key="item.id" :label="item.warehouseName"
                    :value="item.id"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="生效时间" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.isImmediate">立即生效</span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="{ row }">
                <p @click="detaleCompy(row)" style="color:red;cursor: pointer;text-decoration:underline">删除</p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="automatedDloding = false; selectedStoreList = []">取消</el-button>
        <el-button type="primary" :loading="receiptTypeBtnLoading" @click.native="automatedSubmit">确定</el-button>
      </span>
    </el-dialog>



    <!-- 禁用弹框 -->
    <el-dialog class="acc_dialog" :title="dialogTitle" :visible.sync="dialogVisible" :close-on-click-modal="false"
      width="500px" @closed="handleDialogClosed">
      <div v-if="dialogTitle == '禁用提示'" style="font-size: 14px; color: rgb(255, 104, 123); margin: 0 0 10px 0">
        关闭后该商家的所属的账号均无法登录。
      </div>
      <div style="margin-bottom: 20px; font-weight: 500">
        是否确认{{ dialogTitle == "启用提示" ? "启用?" : "关闭?" }}
      </div>
      <div class="command" style="margin-bottom: 20px" v-if="EnableType == 'danger'">
        <div style="margin-right: 10px">禁用原因:</div>
        <el-input style="width: 368px" maxlength="20" type="textarea" :rows="3" placeholder="请填写禁用原因告知用户(限20字)"
          v-model="disableNote">
        </el-input>
      </div>
      <div class="command">
        <div>动态口令：</div>

        <el-input v-model="formOpenPccommand" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="resetPopupData()">取消</el-button>
        <el-button type="primary" @click.native="submitPopupData()">确定</el-button>
      </span>
    </el-dialog>

    <!-- 备注弹出框 -->
    <el-dialog class="acc_dialog" :title="Remarkstitle" :visible.sync="RemarksShow" :close-on-click-modal="false"
      width="500px" @closed="handleDialogClosed">
      <div style="margin-bottom: 20px">备注内容</div>
      <el-input maxlength="50" type="textarea" :rows="4" placeholder="请输入备注内容,最多50字" v-model="RemarkslogTxt">
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="Remarks_log_cl">取消</el-button>
        <el-button type="primary" @click.native="Remarks_log_sure">确定</el-button>
      </span>
    </el-dialog>

    <!-- 启用禁用弹窗 -->
    <!-- <GlobalDialog
      :title="dialogTitle"
      :show="dialogVisible"
      :width="dialogWidth"
      @resetPopupData="resetPopupData"
      @submitPopupData="submitPopupData"
      @onClose="onClose"
      >
      <div style="font-size:16px;color:#666666;">确认要执行该操作吗？</div>
    </GlobalDialog> -->
    <!--明细弹框-->
    <DialogTbale @onClose="onClose" :show="deleteVisible" :title="title" :loading="dialogLoading"
      :columns="DialogTableColumns" :data="DialogDataList" :currentPage="pageNum" :total="total" :pageSize="pageSize"
      :width="1260" @handleSelectionChange="unitySelectionChange" @handleCurrentChange="handleCurrentChangeDialog"
      :isSelection="true">
      <!-- 合作商家弹框新增查询 -->
      <GlobalForm v-if="slotNameHZ" :form-item-list="unionMercItemList" :inline="true" round
        @handleConfirm="listCompany(1)" confirmBtnName="查询">
        <el-form-item label="门店商家" label-width="80px" slot="companyName" style="margin:0">
          <el-input clearable placeholder="请输入商家名称查询" v-model="companyName">
          </el-input>
        </el-form-item>
        <!-- <el-form-item class="el_from" label="合作状态" slot="cooperationState" style="margin:0" label-width="70px">
          <el-select v-model="cooperationState" placeholder="请选择" clearable filterable>
            <el-option v-for="item in cooperationStateList" :key="item.id" :label="item.name"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item class="el_from" label="收货方" slot="Receiver" style="margin:0" label-width="70px">
          <el-select v-model="Receiver" placeholder="请选择" clearable filterable>
            <el-option v-for="item in ReceiverList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="el_from" label="是否为矩阵映射商户" slot="isMatrixCompany" style="margin:0" label-width="140px">
          <el-select v-model="isMatrixCompany" placeholder="请选择" clearable>
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </el-form-item>
      </GlobalForm>
      <!-- 合作商家弹框新增按钮-->
      <div v-if="slotNameHZ"
        style="margin-bottom: 15px;display: flex;align-items: center;justify-content: space-between;">
        <div>
          <div class="disltBtn">
            <div :class="{ none: true, active: cooperationState == '01' }" @click="statusClick('01')">
              合作中({{ midellData.middleIngNum }}个)
            </div>
            <div :class="{ none: true, active: cooperationState == '02' }" @click="statusClick('02')">
              关闭报价({{ midellData.middleCloseNum }}个)
            </div>
            <div :class="{ none: true, active: cooperationState == '00' }" @click="statusClick('00')">
              邀请待同意({{ midellData.middleWaitNum }}个)
            </div>
          </div>
        </div>
        <div>
          <el-button :disabled="unitySelectList.length == 0" type="danger" size="mini"
            @click="Virtualfication('off')">批量关闭虚拟报价</el-button>
            <el-button :disabled="unitySelectList.length == 0" type="primary" size="mini"
            @click="Virtualfication('on')">批量开启虚拟报价</el-button>
          <el-button :disabled="unitySelectList.length == 0" @click="Batchunification" size="mini"
            type="success">批量开启统一报价配置</el-button>
          <el-button :disabled="isNiceMerchant" v-if="cooperationState != '01'" type="primary" size="mini"
            @click="bulkQuote('open')">批量开启报价</el-button>
          <el-button :disabled="isNiceMerchant" v-else type="danger" size="mini"
            @click="bulkQuote('close')">批量关闭报价</el-button>
        </div>
      </div>
      <!-- ********************* -->
      <div class="" style="margin-bottom: 20px" v-if="detailedShow">
        <div class="button_top">
          <div class="radiobtn">
            <div class="faultBtn">
              <div v-bind:class="detailedType === '01' ? 'left' : 'right'" @click="BtnClick('active','01')">
                获得
              </div>
              <div v-bind:class="detailedType === '02' ? 'left' : 'right'" @click="BtnClick('before','02')">
                使用
              </div>
            </div>
          </div>
        </div>
        <div style="margin-bottom: 20px">
          <el-radio-group v-model="detailedTab" @change="detailedChange" size="medium">
            <el-radio-button label="充值明细" v-if="detailedType === '01'"></el-radio-button>
            <el-radio-button label="退回明细" v-if="detailedType === '01'"></el-radio-button>
            <el-radio-button label="收货使用明细" v-if="detailedType === '02'"></el-radio-button>
            <el-radio-button label="绑码使用明细" v-if="detailedType === '02'"></el-radio-button>
            <el-radio-button label="苹果锁使用明细" v-if="detailedType === '02'"></el-radio-button>
            <el-radio-button label="撮合扣款明细" v-if="detailedType === '02'"></el-radio-button>
            <!-- 230907***新增 -->
            <el-radio-button label="串号查询使用明细" v-if="detailedType === '02'"></el-radio-button>
            <el-radio-button label="下账明细" v-if="detailedType === '02'"></el-radio-button>
          </el-radio-group>
        </div>
        <div class="flex">
          <div class="flex">
            <span style="width: 75px" v-if="staffType != '绑定'">{{ staffType }}：</span>
            <el-select v-model="detailedSearch.staffId" filterable size="mini" clearable :placeholder="'请选择' + staffType"
              v-if="staffType !== '门店商家' &&
                staffType != '撮合渠道' &&
                staffType != '绑定'
                ">
              <el-option v-for="item in staffList" :key="item.staffId" :label="item.staffName" :value="item.staffId">
              </el-option>
            </el-select>
            <!-- 新增****串号查询 -->
            <div v-if="staffType === '查询人'" style="display: flex;align-items: center;margin: 0 15px;">
              <span style="width: 60px;">串号：</span><el-input size="mini" placeholder="请输入串号" v-model="detailedSearch.imei"
                clearable>
              </el-input>
            </div>
            <el-select v-model="detailedSearch.companyId" filterable size="mini" clearable
              :placeholder="'请选择' + staffType" v-if="staffType == '门店商家'">
              <el-option v-for="item in staffList" :key="item.id" :label="item.companyName" :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="detailedSearch.matchChannelId" filterable size="mini" clearable
              :placeholder="'请选择' + staffType" v-if="staffType == '撮合渠道'">
              <el-option v-for="item in channelSelectList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="flex" v-if="staffType == '撮合渠道'" style="margin-left: 10px">
            <span style="width: 98px">成交门店商户：</span>
            <el-select v-model="detailedSearch.companyId" filterable size="mini" clearable placeholder="请选择成交门店商户">
              <el-option v-for="item in alreadyCompanyList" :key="item.companyId" :label="item.companyName"
                :value="item.companyId">
              </el-option>
            </el-select>
          </div>
          <div class="flex" style="margin-left: 10px">
            <span style="width: 47px">时间：</span>
            <el-date-picker size="mini" v-model="detailedSearch.time" type="daterange" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
              value-format="timestamp">
            </el-date-picker>
          </div>
          <el-button type="primary" style="margin-left: 10px" size="mini" @click="searchSetting">
            查询
          </el-button>
        </div>
      </div>
      <el-table-column v-if="slotNameHZ === false" label="姓名" slot="staffName" align="center" width="140" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <div class="flex">
            <el-tag size="mini" :key="row.isMajor
              ? '创建人'
              : row.positionType == '01'
                ? '子账号'
                : '报价师'
              " :type="row.isMajor
    ? 'danger'
    : row.positionType == '01'
      ? 'warning'
      : 'success'
    " effect="dark">
              {{
                row.isMajor
                ? "创建人"
                : row.positionType == "01"
                  ? "子账号"
                  : "报价师"
              }}
            </el-tag>
            <span style="margin-left: 5px">{{ row.staffName }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="账号状态" slot="isEnable" align="center" v-if="slotNameHZ === false">
        <template slot-scope="{ row }">
          <span :style="row.isEnable ? 'color:green;' : 'color:red;'">{{
            row.isEnable ? "启用中" : "已禁用"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否关注公众号" slot="isSubscribe" align="center" v-if="slotNameHZ === false">
        <template slot-scope="{ row }">
          <span :style="row.isSubscribe == '01' ? 'color:green;' : 'color:red;'">{{ row.isSubscribe == "01" ? "是" : "否"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="公众号消息推送状态" slot="officialAccounts" align="center" v-if="slotNameHZ === false">
        <template slot-scope="{ row }">
          <span :style="row.isWechatPush ? '' : 'color:red;'">{{ row.isWechatPush ? "推送中" : "已停止"
          }}</span>
          <el-link type="primary" @click="$refs['pushRecordDia'].open(row)">查看记录</el-link>
        </template>
      </el-table-column>
      <el-table-column label="回收额度" slot="recoveryQuota" align="center" v-if="slotNameHZ === false">
        <template slot-scope="{ row }">
          <span v-if="!currentRow.isAccountLimit">未配置</span>
          <span v-else>{{ row.limitType=="01" ? row.limitBalance : "不限额度"}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" slot="staffCountOperation" align="center" width="220px" v-if="slotNameHZ === false" fixed="right">
        <template slot-scope="{ row }">
          <el-button round :type="row.isWechatPush ? 'danger' : 'primary'" size="mini" @click="$refs['changePushDia'].open(row)">{{row.isWechatPush ? '停止推送' : '开始推送'}}</el-button>
          <el-button round type="warning" size="mini" @click="cleanWechatInfo(row.staffId)">清除微信信息</el-button>
        </template>
      </el-table-column>
      <el-table-column label="合作回收商" slot="operation" align="center" v-if="slotNameHZ === true">
        <template slot-scope="{ row }">
          <span @click="DialoghandleBtn(row, 'Number')" class="tableBtn"><span style="border-bottom: 1px solid #0981ff">{{
            row.merchants
          }}</span></span>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" slot="state" align="center" v-if="slotNameHZ === false && !hezuohui">
        <template slot-scope="{ row }">
          <span v-if="row.state === '00'">待确认</span>
          <span v-else-if="row.state === '01'">待绑码</span>
          <span v-else-if="row.state === '02'">待支付</span>
          <span v-else-if="row.state === '03'">已绑码</span>
          <span v-else-if="row.state === '04'">已收货</span>
          <span v-else-if="row.state === '05'">降价收货</span>
          <span v-else-if="row.state === '10'">已取消</span>
          <span v-else-if="row.state === '20'">已作废</span>
          <span v-else-if="row.state === '30'">已退回</span>
        </template>
      </el-table-column>
      <el-table-column label="交易店员" slot="companyStaffName" align="center" v-if="staffType == '门店商家'">
        <template slot-scope="{ row }">
          <span>{{ row.companyStaffName
          }}{{ row.companyStoreName ? `—${row.companyStoreName}` : "" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否有效" slot="imeiEffective" align="center" v-if="staffType == '门店商家'">
        <template slot-scope="{ row }">
          <span>{{ row.imeiEffective == "1" ? "有效" : "无效" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="合作状态" slot="operation" align="center" v-if="slotNameHZ === true && !hezuohui">
        <template slot-scope="{ row }">
          <span>{{
            row.applyStatus == "00"
            ? "邀请待同意"
            : row.applyStatus == "01"
              ? "合作中"
              : row.applyStatus == "02"
                ? "邀请未通过"
                : "取消合作"
          }}
            {{
              row.companyIsEnable == "02" &&
              row.applyStatus != "00" &&
              row.applyStatus != "02"
              ? "(关闭报价)"
              : ""
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="所属渠道" slot="channelName" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.channelName || '--'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="使用矩阵" slot="matrixName" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.matrixName || '--'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="回收商在矩阵报价状态" slot="merchantInMatrixState" align="center">
        <template slot-scope="{ row }">
          <span :style="row.merchantInMatrixState == '02' ? 'color:red' : ''">{{ optionsFilter(row.merchantInMatrixState, merchantInMatrixStateOptions) || '--'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否开启ID验证" slot="isValidAppleId" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.isValidAppleId ? "是" : "否" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="使用统一报价配置" v-if="slotNameHZ === true" :key="99" slot="uniformpirce" align="center">
        <template slot-scope="{ row }">
          <el-switch key="no" @change="uniformSwitch(row)" v-model="row.isDefaultQuoteMachine"></el-switch>
        </template>
      </el-table-column>
      <!-- 是否可联系店员 -->
      <el-table-column label="是否可联系店员" :key="19" slot="isflaigstaff" align="center">
        <template slot-scope="{ row }">
          <el-switch key="no" @change="contactStaffSwitch(row)" v-model="row.isContact"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="收货方" slot="receiveParty" align="center">
        <template v-slot="{ row }">
          <span>{{ row.receiveParty }}</span>
        </template>
      </el-table-column>
      <el-table-column label="启用虚拟报价" v-if="slotNameHZ === true" :key="9967" slot="Virtualpirce" align="center">
        <template slot-scope="{ row }">
          <el-switch key="no" :disabled="row.isMatrixCompany || row.isOut" @change="VirtualSwitch(row)" v-model="row.isOpenMockQuote"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140" fixed="right" slot="offerOperation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" :type="row.companyIsEnable == '01' ? 'danger' : 'primary'"
            @click="offerOperation(row)">{{ row.companyIsEnable == "01" ? "关闭报价" : "开启报价" }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="账单状态" slot="opt" align="center">
        <template slot-scope="{ row }">
          <!-- 01：撮合入账，02：撮合出账 -->
          <div>{{ row.opt == "01" ? "撮合入账" : "撮合出账" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="渠道扣款（次）" slot="Tax" align="center">
        <template slot-scope="{ row }">
          <div :style="row.opt == '01' ? 'color: green;' : 'color: red;'">
            {{ row.opt == "01" ? "+" : "-" }}{{ row.channelTax }}
          </div>
        </template>
      </el-table-column>
      <!-- 230907***新增 -->
      <el-table-column label="查询人" slot="usedStaff" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.usedStaffName }}-{{ row.usedStaffPhone }}</div>
        </template>
      </el-table-column>
      <el-table-column label="繁星扣款（次）" slot="Tax" align="center">
        <template slot-scope="{ row }">
          <div :style="row.opt == '01' ? 'color: green;' : 'color: red;'">
            {{ row.opt == "01" ? "+" : "-" }}{{ row.btstarsTax }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="绑码人" slot="bindingStaff" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.companyStaffName }}-{{ row.companyStaffPhone }}</span>
        </template>
      </el-table-column>
      <el-table-column label="下单人" slot="returnStaff" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.companyStaffName }}-{{ row.companyStaffPhone }}</span>
        </template>
      </el-table-column>
    </DialogTbale>
    <!-- 合作商家批量开启/关闭报价弹框 -->
    <el-dialog :title="`批量${bulkDialogTitle}报价`" :visible.sync="bulkDialogShow" :close-on-click-modal="false"
      width="880px" @closed="bulkDialogClosed">
      <el-table v-if="tableData.length" border stripe max-height="600" ref="multipleTable2" :row-key="(row) => row.id"
        :data="tableData" :selection="companyIds" tooltip-effect="dark" style="width: 100%" @select="bulkHandleSelect"
        @select-all="bulkHandleSelectAll">
        <el-table-column :reserve-selection="true" type="selection" width="55" align="center">
        </el-table-column>
        <el-table-column type="index" label="序号" width="65" align="center">
        </el-table-column>
        <el-table-column prop="companyName" label="商户名称" align="center">
        </el-table-column>
        <el-table-column prop="contact" label="联系人" align="center">
        </el-table-column>
        <el-table-column prop="phone" label="联系电话" align="center">
        </el-table-column>
        <el-table-column prop="stores" label="门店数量" align="center">
        </el-table-column>
      </el-table>
      <el-pagination v-if="tableData.length" style="text-align:right;margin: 20px 0;" background
        @current-change="bulkCurrentChangeDialog" :current-page.sync="bulkPage.pageNum" :page-sizes="[10, 20, 30, 40]"
        :page-size="bulkPage.pageSize" layout="total, prev, pager, next, jumper" :total="bulkPage.total">
      </el-pagination>
      <div v-if="tableData.length" style="text-align:right;padding-bottom:30px;">
        <el-button @click.native="bulkDialogClosed">取消</el-button>
        <el-button type="primary" @click.native="bulkDialogSuccess">确定</el-button>
      </div>
      <div v-if="tableData.length <= 0"
        style="width: 100%; text-align: center; padding: 80px 0px; font-size: 22px; color:#666; background: white;">
        暂无数据~
      </div>
    </el-dialog>
     <!-- 同步erp数据 -->
     <el-dialog title="操作提示" :visible.sync="erpDialogShow" :close-on-click-modal="false" width="450px" @close="baoDialogClose">
      <div style="font-size: 13px; color: red">
        <span v-if="HealthCodeCusdata.isSyncErp">说明: 启用后，该商户小程序显示”同步erp“入口</span>
        <span v-else>说明: 关闭后，该商户小程序不显示”同步erp“入口</span>
      </div>
      <div style="text-align: center;margin:15px 0 20px 0">是否确认{{HealthCodeCusdata.isSyncErp?'开启':'关闭'}}<span style="color:#0981ff">{{HealthCodeCusdata.merchantName}}</span>的同步erp数据功能？</div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          @input="(val) => (this.command = val.replace(/[^0-9a-zA-Z]/gi, ''))"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="erpDialogShow = false">取消</el-button>
        <el-button type="primary" :loading="offerDialogBtnLoading" @click.native="ERPtimatedDialogSure">确定</el-button>
      </span>
    </el-dialog>
    <!-- 批量开启/关闭报价确认弹框 -->
    <el-dialog :title="`${bulkDialogTitle}报价提示`" :visible.sync="bulkSureShow" :close-on-click-modal="false" width="500px">
      <div style="font-size: 14px; color: red" v-if="bulkDialogTitle == '开启'">
        说明：批量开启报价，门店商家的手续费默认为<span style="font-weight:600;">回收商承担</span>
      </div>
      <div style="font-size: 14px; color: red" v-if="bulkDialogTitle == '关闭'">
        说明：关闭报价后，该回收商家不再参与这些门店商的报价
      </div>
      <div class="mb30 mt20">
        <div style="text-align: center;" class="fzs16 mb20 texleft">
          是否确认{{ bulkDialogTitle == "开启" ? "启用" : "关闭"
          }}<span style="color: red">{{ bmerchantName || "--" }}</span>在<span style="color: red">以上{{ companyIds.length
}}个商家</span>的报价？
        </div>
      </div>
      <div style="margin:-10px 0 15px 0;text-align: center;" v-if="bulkDialogTitle == '关闭' && closePriceNumber">
        当前待确认订单中，<span style="color:red">有{{ closePriceNumber }}单该商家报价最高</span>，关闭报价请确认好
      </div>
      <div class="command">
        <div>动态口令：</div>
        <el-input v-model="bulkCommand" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="bulkSureShow = false">取消</el-button>
        <el-button type="primary" @click.native="bulkSureSuccess">确定</el-button>
      </span>
    </el-dialog>
     <!-- 虚拟报价配置弹窗 -->
     <el-dialog title="操作提示" :visible.sync="VirtualVisible" :close-on-click-modal="false" width="460px"
      @closed="unityDialogClosed">
      <div style="font-size: 13px; color: red">
        <span v-if="isOpenMockQuote">说明: 启用后，该回收商超时未报价或拒绝报价后，门店将展示虚拟报价，虚拟报价无法成交</span>
        <span v-else>说明: 关闭后，该回收商超时未报价或拒绝报价后，系统不再虚拟价格</span>
      </div>
      <div class="centercontent">
        是否确认{{ !isOpenMockQuote ? '关闭' : '开启' }}在<span style="color:red">{{ unitsNumber }}个门店商户</span>下的虚拟报价功能?
      </div>
      <div class="command">
        <div>动态口令：</div>
        <el-input v-model="contactCommand" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="VirtualVisible = false; contactCommand = ''">取消</el-button>
        <el-button type="primary" @click.native="VirtualSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 开启使用统一配置弹窗 -->
    <el-dialog title="操作提示" :visible.sync="uniformVisible" :close-on-click-modal="false" width="460px"
      @closed="unityDialogClosed">
      <div style="font-size: 13px; color: red">
        <span v-if="isUniformly">说明: 启用后，使用该回收商统一配者的报价品类</span>
        <span v-else>说明: 关闭后，使用单独回收向在该门店向单独配苦的报价品类</span>
      </div>
      <div class="centercontent">
        是否确认{{ !isUniformly ? '关闭' : '开启' }}所选<span style="color:red">{{ unitsNumber }}个门店商户</span>使用统一报价配置?
      </div>
      <div class="command">
        <div>动态口令：</div>
        <el-input v-model="contactCommand" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="uniformVisible = false; contactCommand = ''">取消</el-button>
        <el-button type="primary" @click.native="uniSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 是否推荐 -->
    <el-dialog :title="`${dialogRecomTitle}推荐`" :visible.sync="RecomVisible" :close-on-click-modal="false" width="380px"
      @closed="handleDialogClosed">
      <div style="font-size: 12px; color: red">{{ this.minRedText }}</div>
      <div style="
                                    font-size: 18px;
                                    color: #666666;
                                    text-align: center;
                                    height: 100px;
                                    line-height: 100px;
                                  ">
        确认要{{ this.dialogRecomTitle }}吗？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="handleSuccess">确定</el-button>
        <el-button @click.native="handleClose">取消</el-button>
      </span>
    </el-dialog>
    <!-- 是否可联系店员 -->
    <el-dialog :title="`${dialogContactStaffTitle}提示`" :visible.sync="contactStaffVisible" :close-on-click-modal="false"
      width="460px" @closed="contactStaffDialogClosed">
      <div style="font-size: 12px; color: red">
        {{ this.contactStaffRedText }}
      </div>
      <div style="
                                    font-size: 16px;
                                    color: #666666;
                                    text-align: center;
                                    height: 100px;
                                    line-height: 100px;
                                  ">
        是否确认{{
          this.dialogContactStaffTitle == "关闭" ? "禁用" : "启用"
        }}回收商联系店员？
      </div>
      <div class="command">
        <div>动态口令：</div>
        <el-input v-model="contactCommand" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="contactStaffClosed">取消</el-button>
        <el-button type="primary" @click.native="contactStaffhandleSuccess">确定</el-button>
      </span>
    </el-dialog>
    <!-- 报价设置弹框 -->
    <el-dialog title="报价设置" :visible.sync="SetWaitQuoteJumpVisible" :close-on-click-modal="false" width="480px">
      <div style="font-size: 12px; color: red">
        说明：选择不可跳过，则回收端小程序-待报价中页面底部显示【无法报价】按钮
      </div>
      <!-- 待报价是否可跳过订单 -->
      <div class="mb30 mt20">
        <div class="fzs16 mb20 texleft">待报价是否可跳过订单</div>
        <div>
          <el-radio v-model="isWaitQuoteJump" :label="true">可跳过</el-radio>
          <el-radio v-model="isWaitQuoteJump" :label="false">不可跳过（显示驳回按钮）</el-radio>
        </div>
      </div>
      <div class="command">
        <div>动态口令：</div>
        <el-input v-model="setJumpcommand" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="SetWaitQuoteJumpVisible = false">取消</el-button>
        <el-button type="primary" @click.native="SetJumpSuccess">确定</el-button>
      </span>
    </el-dialog>
    <!-- 查看物流设置 -->
    <el-dialog title="查看物流设置" :visible.sync="setDialogVisible" :close-on-click-modal="false" width="458px">
      <div class="verify-title"><span>商家信息</span></div>
      <div class="mb-20" style="font-size: 14px; color: #333333">
        <div class="mb-10">
          商家名称：<span style="color: #666666">{{
            rulesInfo.merchantName
          }}</span>
        </div>
        <div>
          联系人：<span style="color: #666666">{{ rulesInfo.contact }}</span><span style="margin-left: 20px">联系电话：</span><span
            style="color: #666666">{{ rulesInfo.phone }}</span>
        </div>
      </div>
      <div class="verify-title"><span>快递选择</span></div>
      <div class="flex mb-20" style="font-size: 14px; color: #333333">
        <div class="mr-50">
          首选：<span style="color: #666666">{{
            expressList.length ? expressList[0].expressName : "--"
          }}</span>
        </div>
        <div>
          次选：<span style="color: #666666">{{
            expressList.length ? expressList[1].expressName : "--"
          }}</span>
        </div>
      </div>
      <div class="verify-title"><span>发货价值设置</span></div>
      <div class="mb-20" style="font-size: 14px; color: #333333">
        <div>
          <span style="color: #666666">旧机总报价≥</span><span class="ml-10 mr-10 font-w">{{ rulesInfo.minCostSend
          }}</span><span style="color: #666666">元门店可发货一次</span>
        </div>
      </div>
      <div class="verify-title"><span>发货规则</span></div>
      <div class="mb-20" style="font-size: 14px; color: #333333">
        <div class="mb-10">
          发货订单：<span style="color: #666666">当日发货前一日及以前成交订单</span>
        </div>
        <div class="mb-10 flex flex-as">
          <div>发货提醒规则：</div>
          <div style="color: #666666">
            <div>济南繁星回收门店端公众号消息推送</div>
            <div>
              <span style="color: #ff687b">次日早上8:00-9:00</span>发送消息提醒
            </div>
            <div>
              <span style="color: #0981ff">次月2号</span>再次推送上月未发货订单消息提醒
            </div>
          </div>
        </div>
        <div>
          发货提醒人员：<span style="color: #666666">门店店长、下单店员</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="setDialogClose" plain>关闭</el-button>
      </span>
    </el-dialog>
    <!-- //批量创建合作关系 -->
    <el-dialog title="添加确认" :visible.sync="largelyDialogShow" :close-on-click-modal="false" width="450px"
      @close="offerDialogClose">
      <div class="enable-tip" style="margin-bottom:0;">
        <div>提示: 请与连锁门店业务负责人核实确认手续费承担方</div>
      </div>
      <el-radio-group v-model="procedures" style="margin-top: 18px; margin-bottom: 22px">
        <el-radio label="01">门店承担手续费</el-radio>
        <el-radio label="02">回收商承担手续费</el-radio>
      </el-radio-group>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          @input="(val) => (this.command = val.replace(/[^0-9a-zA-Z]/gi, ''))"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="largelyDialogShow = false">取消</el-button>
        <el-button type="primary" :loading="offerDialogBtnLoading" @click.native="largeSure">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="relationRow.companyIsEnable == '01' ? '关闭报价提示' : '开启报价提示'
      " :visible.sync="offerDialogShow" :close-on-click-modal="false" width="450px" @close="offerDialogClose">
      <div v-if="relationRow.payApplyStatus != '02' ||
        relationRow.companyIsEnable == '01'
        ">
        <div class="enable-tip" v-if="relationRow.companyIsEnable == '01'">
          <div>说明：关闭报价后，该商家不再参与门店的报价。</div>
          <div class="tip-sure" style="text-align: left;">
            是否确认关闭<span style="color:#0981FF">{{
              relationRow.companyName
            }}</span>的报价？
          </div>
        </div>
        <div class="enable-tip" v-else>
          <div>说明：开启报价后，商家将继续参与门店商家的报价。</div>
          <div class="tip-sure" style="text-align: left;">
            是否确认开启<span style="color:#0981FF">{{
              relationRow.companyName
            }}</span>的报价？
          </div>
        </div>
        <div style="margin:-10px 0 15px 0;" v-if="closePriceNumber">
          当前待确认订单中，<span style="color:red">有{{ closePriceNumber }}单该商家报价最高</span>，关闭报价请确认好
        </div>
      </div>
      <div class="enable-tip" style="margin-bottom:0;" v-if="relationRow.companyIsEnable == '02' &&
        relationRow.payApplyStatus == '02'
        ">
        <div>说明：商家已启用线上支付，请选择手续费承担方</div>
      </div>
      <el-radio-group v-if="relationRow.payApplyStatus == '02' &&
        relationRow.companyIsEnable == '02'
        " v-model="procedures" style="margin-top: 18px; margin-bottom: 22px">
        <el-radio label="01">门店承担手续费</el-radio>
        <el-radio label="02">回收商承担手续费</el-radio>
      </el-radio-group>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          @input="(val) => (this.command = val.replace(/[^0-9a-zA-Z]/gi, ''))"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="offerDialogShow = false">取消</el-button>
        <el-button type="primary" :loading="offerDialogBtnLoading" @click.native="offerDialogSure">确定</el-button>
      </span>
    </el-dialog>
    <!-- 报价填写预估机况 -->
    <el-dialog title="操作提示" :visible.sync="estimatedDialogShow" :close-on-click-modal="false" width="450px" @close="baoDialogClose">
      <div style="text-align: center;margin-bottom: 20px">是否确认{{HealthCodeCusdata.isHealthCodeCus?'开启':'关闭'}}报价填写预估机况？</div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          @input="(val) => (this.command = val.replace(/[^0-9a-zA-Z]/gi, ''))"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="estimatedDialogShow = false">取消</el-button>
        <el-button type="primary" :loading="offerDialogBtnLoading" @click.native="estimatedDialogSure">确定</el-button>
      </span>
    </el-dialog>
      <!-- 回收额度配置 -->
      <el-dialog title="操作提示" :visible.sync="AccountLimitDialogShow" :close-on-click-modal="false" width="450px" @close="baoDialogClose">
      <div style="text-align: center;margin-bottom: 20px">是否确认{{HealthCodeCusdata.isAccountLimit?'开启':'关闭'}}回收额度配置？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="AccountLimitDialogShow = false">取消</el-button>
        <el-button type="primary" :loading="offerDialogBtnLoading" @click.native="isAccountLimitDialogSure">确定</el-button>
      </span>
    </el-dialog>
    <!-- 回收商管理后台 分配账号 -->
    <el-dialog class="acc_dialog" :title="dialogTitle" :visible.sync="assignAccDialogVisible"
      :close-on-click-modal="false" width="500px" @closed="handleDialogClosed">
      <div style="font-size: 14px; color: #ff687b">{{ tipTxt }}</div>
      <div v-if="dialogTitle == '提示'" style="
                  font-size: 16px;
                  color: #666666;
                  height: 100px;
                  line-height: 50px;
                ">
        <div>是否确认{{ tipOpenPc }}该商家的回收商后台管理系统？</div>
        <div class="command">
          <div>动态口令：</div>
          <el-input v-model="formOpenPc.command" placeholder="请输入谷歌动态口令"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
        </div>
      </div>

      <el-form ref="form" :model="account" v-if="dialogTitle == '分配账号' || dialogTitle == '查看账号'" style="margin-top: 25px">
        <el-form-item label="账号">
          <el-input v-model="account.acc" placeholder="请输入回收商账号" 
            @blur="account.acc = $event.target.value"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
          <el-button type="primary" size="small" style="margin-left: 20px" v-if="dialogTitle == '分配账号'"
            @click="createPcAcc">自动生成账号
          </el-button>
        </el-form-item>
        <el-form-item label="密码">
          <el-input :type="type" disabled placeholder="请输入回收商密码" onfocus="this.type='password'" v-model="account.pwd"
            autocomplete="off">
            <i v-if="dialogTitle != '查看账号' || isReset" slot="suffix" class="pwd-style" :class="elIcon" autocomplete="auto"
              @click="pwdFlag = !pwdFlag" />
          </el-input>
          <el-button type="primary" v-if="dialogTitle == '查看账号'" size="small" style="margin-left: 20px"
            @click="reset">重置密码
          </el-button>
        </el-form-item>
        <el-form-item label="" label-width="40px">
          <span v-if="dialogTitle == '分配账号'">初始密码默认为创建人手机号码，不可修改</span>
          <span v-else>重置后密码为初始密码，为创建人手机号码</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="handleClosed">取消</el-button>
        <el-button type="primary" @click.native="handleAccountSuccess">确定</el-button>
      </span>
    </el-dialog>
    <!-- 导入指定机型 -->
    <el-dialog class="add-dialog" :title="setionBranddata.machineRange=='02'?'导入报价机型':'导入不报价机型'" :visible.sync="importVisible" :close-on-click-modal="false" width="510px"
      @closed="exportinDialogClosed">
      <div class="step">
        <div class="step-title" v-if="setionBranddata.machineRange=='02'">
          <span>说明：需先下载机型数据，将挑选后的机型数据上传表格，表格中的机型ID不可修改</span>
        </div>
        <div class="step-title" v-if="setionBranddata.machineRange=='03'">
          <span>说明：需先下载机型数据，将回收商不报价的机型数据上传表格，表格中的机型ID不可修改</span>
        </div>
        <div class="step-content">
          <el-button type="success" icon="el-icon-download" size="medium" @click="download">下载数据模板</el-button>
        </div>
        <el-form ref="importForm" :model="importForm" :rules="importRules" label-width="80px">
          <el-form-item label="表格上传" prop="fileName" class="fileName">
            <el-input v-model="importForm.fileName" @clear="fileClear" :disabled="true" size="medium" clearable>
            </el-input>
            <div style="float: right; position: relative">
              <el-button size="medium" type="warning" icon="el-icon-upload2">上传表格</el-button>
              <input accept=".xls, .xlsx" ref="fileUpload" @change="onUploadList" type="file" style="
                  opacity: 0;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  cursor: pointer;
                " />
            </div>
            <div style="color: #ff687b">
              仅支持.xls及.xlsx格式,单次上传大小不超过5M
            </div>
          </el-form-item>
        </el-form>
        <div class="step2-content"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importVisible = false">取消</el-button>
        <el-button type="primary" @click.native="uploadFile">确定</el-button>
      </span>
    </el-dialog>
    <!-- 关联门店商户弹窗 -->
    <DialogAction v-bind="associationProp" @close="associationProp.show = false"
      @codeChange="(v) => (associationProp.code = v)" @config="associationPropConfig" />
    <DialogAction v-bind="myMethodsProps" @close="
      myMethodsProps.show = false;
    handleCurrentChange(page.pageNum);
    " @codeChange="(v) => (myMethodsProps.code = v)" @config="myMethodsConfig">
      <template v-slot:from>
        <div style="margin-bottom: 30px;" v-if="myMethodsProps.state">
          是否确认开启<span class="lv_fc">{{
            myMethodsProps.currentData.merchantName || "--"
          }}</span>线上结算服务费？
        </div>
        <div style="margin-bottom: 30px;" v-else>
          是否确认关闭<span class="lv_fc">{{
            myMethodsProps.currentData.merchantName || "--"
          }}</span>线上结算服务费？
        </div>
      </template>
    </DialogAction>
    <!-- 报价品类设置 -->
    <el-dialog title="报价品类配置" :visible.sync="SetcategoryDloding" :close-on-click-modal="false" width="1000px"
      @closed="receiptTypeClosed">
      <div style="max-height: 480px;overflow: auto;">
        <div class="dis_flex al_c set_top_ck">
          <span>选择报价分类/品牌</span>
          <div class="enable-tip" style="margin-bottom: 0;font-size: 12px;">
            (说明：按照分类配置回收商报价品牌，默认全选)
          </div>
        </div>
        <div class="categorySet" v-for="(item, index) in alltypeData" :key="index">
          <div class="cs_center dis_flex al_c ju_sb">
            <div class="selection_type">
              <img v-if="item.selected" src="https://img.fanxinghuishou.cn/20231128/086243815.png" alt="">
              <img v-else src="https://img.fanxinghuishou.cn/20231128/808620386.png" alt="">
              <span>{{ item.name }}</span>
            </div>
            <div @click="Setquemodel(item, index)" style="text-decoration:underline;color: #e6a23c;cursor: pointer;">
              设置报价机型
            </div>
          </div>
          <div style="margin: 15px 0;"></div>
          <div style="padding: 0 25px;display: flex;flex-wrap: wrap;">
            <div class="selection_type" style="margin-bottom: 10px;" v-for="(city, ctindex) in item.brands"
              :key="ctindex">
              <img v-if="city.selected" src="https://img.fanxinghuishou.cn/20231128/086243815.png" alt="">
              <img v-else src="https://img.fanxinghuishou.cn/20231128/808620386.png" alt="">
              <span>{{ city.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="dis_flex al_c set_top_ck"><span>选择开机情况</span></div>
        <div class="categorySet">
          <div class="cs_center dis_flex al_c">
            <el-checkbox :indeterminate="quoteMachineConditionisIndeterminate" v-model="quoteMachineConditioncheckAll"
              @change="quoteMachineConditionallChange">全选</el-checkbox>
          </div>
          <div style="margin: 15px 0;"></div>
          <div style="padding: 0 25px;">
            <el-checkbox-group v-model="quoteMachineConditioncheckedCities" @change="quoteMachineConditionChange">
              <el-checkbox v-for="city in quoteMachineConditioncities" :label="city.value" :key="city.value">{{ city.value
              }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div>
        <div class="dis_flex al_c set_top_ck">
          <span>选择旧机使用情况</span>
        </div>
        <div class="categorySet">
          <div class="cs_center dis_flex al_c">
            <el-checkbox :indeterminate="quoteMachineHealthisIndeterminate" v-model="quoteMachineHealthcheckAll"
              @change="quoteMachineHealthallChange">全选</el-checkbox>
          </div>
          <div style="margin: 15px 0;"></div>
          <div style="padding: 0 25px;">
            <el-checkbox-group v-model="quoteMachineHealthcheckedCities" @change="quoteMachineHealthChange">
              <el-checkbox v-for="city in quoteMachineHealthcities" :label="city.value" :key="city.value">{{ city.value
              }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div>
        <div class="dis_flex al_c set_top_ck">
          <span>选择价位段</span>
        </div>
        <div class="categorySet" style="height: 120px;">
          <div class="cs_center dis_flex al_c" style="height: 44px;">
            <el-checkbox :indeterminate="quotePriceRangeHealthisIndeterminate" v-model="quotePriceRangeHealthcheckAll"
              @change="quotePriceRangeHealthallChange">全选</el-checkbox>
            <div class="andleShowbox">
              <div :class="[
                [
                  quotePriceType == '01'
                    ? 'andleShowcolor andleShowstyle'
                    : 'andleShowstyle',
                ],
              ]" @click="switchTrigin('01')">
                按靓机价
              </div>
              <div :class="[
                [
                  quotePriceType !== '01'
                    ? 'andleShowcolor andleShowstyle'
                    : 'andleShowstyle',
                ],
              ]" @click="switchTrigin('02')">
                按预估价
              </div>
            </div>
          </div>
          <div style="margin: 15px 0;"></div>
          <div style="padding: 0 25px;">
            <el-checkbox-group v-model="quotePriceRangeHealthcheckedCities" @change="quotePriceRangeHealthChange">
              <el-checkbox v-for="city in quotePriceRangeHealthcities" :label="city.value" :key="city.value">{{ city.value
              }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px;padding-bottom: 10px;">
        <div style="margin-bottom: 20px;">
          最近修改人：{{
            allQuteData.setQuoteAdminAcc
            ? allQuteData.setQuoteAdminAcc +
            "-" +
            allQuteData.setQuoteAdminName
            : "--"
          }}
        </div>
        <div>修改时间：{{ allQuteData.setQuoteAdminTime || "--" }}</div>
      </div>
      <span slot="footer" class="dialog-footer" v-if="!flagDefaultMachine">
        <el-button @click.native="SetcategoryDloding = false">取消</el-button>
        <el-button type="primary" :loading="receiptTypeBtnLoading" @click.native="SetcategorySubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 设置报价机型 -->
    <el-dialog title="设置报价机型" :visible.sync="SetquotemodelDloding" :close-on-click-modal="false" width="1000px"
      @closed="cloSmephone">
      <el-table ref="qotemultipleTable" :data="seaPhoneDataList" tooltip-effect="dark" border v-loading="qutoding"
        style="width: 100%" @selection-change="handleSelect">
        <el-table-column type="selection" align="center"> </el-table-column>
        <el-table-column label="品牌" align="center">
          <template slot-scope="{ row }">{{ row.name || "--" }}</template>
        </el-table-column>
        <el-table-column label="机型选择" align="center">
          <template slot-scope="{ row }">
            <el-select :disabled="row.name == '其他'" @change="modelchange" v-model="row.machineRange" placeholder="请选择">
              <el-option v-for="item in modelTypelist" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="指定机型" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.name == '其他'">--</div>
            <div v-else>
              <div v-if="row.machineRange !== '01'">
                <span style="cursor:pointer;color: rgb(9, 129, 255);text-decoration: underline;"
                  @click="shipMachine(row)">{{ row.assignMachineIds.length }}</span>
                <div style="display:flex;justify-content: center;">
                  <div @click="Additivemodel(row)" style="text-decoration:underline;color: #e6a23c;cursor:pointer;">
                    添加机型
                  </div>
                  <div @click="reportitivemodel(row)"
                    style="margin-left: 20px;text-decoration:underline;color: #67c23a;cursor:pointer;">
                    批量导入机型
                  </div>
                </div>
              </div>
              <div v-else>全部机型</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="cloSmephone">取消</el-button>
        <el-button type="primary" :loading="receiptTypeBtnLoading" @click.native="SetquotemodelSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 添加报价机型 -->
    <el-dialog :title="setionBranddata.machineRange=='02'?'添加报价机型':'添加不报价机型'" :visible.sync="AddquotemodelDloding" :close-on-click-modal="false" width="1000px"
      @closed="AddquotemodelDloding = false, formInline.phoneName = ''">
      <div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="机型名称">
            <el-input v-model="formInline.phoneName" placeholder="请输入机型名称" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="serchModalSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table ref="addtipleTable" :data="opymodelList" tooltip-effect="dark" border max-height="530" style="width: 100%"
        @selection-change="modelleSelect">
        <el-table-column type="selection" align="center"> </el-table-column>
        <el-table-column label="所属品牌" align="center">
          <template slot-scope="{ row }">{{ row.brandName || "--" }}</template>
        </el-table-column>
        <el-table-column label="机型名称" align="center">
          <template slot-scope="{ row }">{{ row.name || "--" }}</template>
        </el-table-column>
        <el-table-column label="机型来源" align="center">
          <template slot-scope="{ row }">{{ row.channel || "--" }}</template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="AddquotemodelDloding = false, formInline.phoneName = ''">取消</el-button>
        <el-button type="primary" :loading="receiptTypeBtnLoading" @click.native="AddmodelSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 删除报价机型 -->
    <el-dialog title="管理报价机型" :visible.sync="deletequotemodelDloding" :close-on-click-modal="false" width="1000px"
      @closed="deletequotemodelDloding = false">
      <div style="display:flex;justify-content:space-between;align-item:center;">
        <el-form :inline="true" :model="deteleformIn" class="demo-form-inline">
          <el-form-item label="机型名称">
            <el-input v-model="deteleformIn.phoneName" placeholder="请输入机型名称" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="deteleserchSubmit">查询</el-button>
          </el-form-item>
        </el-form>
        <div>
          <el-button type="success" icon="el-icon-download" size="medium" :loading="exportLoading" @click="download(1)">导出机型</el-button>
          <el-button @click="detaleAllphone" v-if="deteleIdsList.length > 0" :key="90" type="danger">批量删除</el-button>
          <el-button type="info" v-else :key="80">批量删除</el-button>
        </div>
      </div>
      <el-table ref="addtipleTable" :data="detepymodelList" tooltip-effect="dark" border max-height="530"
        style="width: 100%" @selection-change="deteleModeleSelect">
        <el-table-column type="selection" align="center"> </el-table-column>
        <el-table-column label="所属品牌" align="center">
          <template slot-scope="{ row }">{{ row.brandName || "--" }}</template>
        </el-table-column>
        <el-table-column label="机型名称" align="center">
          <template slot-scope="{ row }">{{ row.name || "--" }}</template>
        </el-table-column>
        <el-table-column label="机型来源" align="center">
          <template slot-scope="{ row }">{{ row.channel || "--" }}</template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <el-button type="danger" size="small" round @click="detaleRedyphone(row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="deletequotemodelDloding = false">关闭</el-button>
        <!-- <el-button
          type="primary"
          :loading="receiptTypeBtnLoading"
          @click.native="detelemodelSubmit"
          >确定</el-button
        > -->
      </span>
    </el-dialog>
    <!-- 查看报价机型 -->
    <el-dialog title="查看报价机型" :visible.sync="seaquotemodelDloding" :close-on-click-modal="false" width="1000px"
      @closed="seaquotemodelDloding = false">
      <div>
        <el-form :inline="true" :model="seaformIn" class="demo-form-inline">
          <el-form-item label="机型名称">
            <el-input v-model="seaformIn.phoneName" placeholder="请输入机型名称" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="SeamodelSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table ref="addtipleTable" :data="seaOymodelList" tooltip-effect="dark" border max-height="540"
        style="width: 100%">
        <el-table-column label="序号" align="center" type="index" width="50">
        </el-table-column>
        <el-table-column label="所属品牌" align="center">
          <template slot-scope="{ row }">{{ row.brandName || "--" }}</template>
        </el-table-column>
        <el-table-column label="机型名称" align="center">
          <template slot-scope="{ row }">{{ row.phnoeName || "--" }}</template>
        </el-table-column>
        <el-table-column label="机型来源" align="center">
          <template slot-scope="{ row }">{{
            row.modelSource || "--"
          }}</template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="seaquotemodelDloding = false">取消</el-button>
      </span>
    </el-dialog>
    <!-- 保存报价 -->
    <el-dialog title="提示" :visible.sync="quotationDialogVisible" :close-on-click-modal="false" width="500px"
      @closed="command = ''">
      <div style="text-align: center;font-size: 16px;margin-bottom: 20px;">
        是否确认保存报价配置？
      </div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="quotationDialogVisible = false">取消</el-button>
        <el-button type="primary" :loading="receiptTypeBtnLoading" @click.native="quotationSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 合作门店 -->
    <DialogTbale @onClose="operateVisible = false" :loading="operateLoading" :show="operateVisible" :title="operateTitle"
      :columns="operateColumns" :data="dialogOperateList" :currentPage="operateParams.pageNum"
      :total="operateParams.total" :pageSize="10" :width="1000" @handleCurrentChange="searchOperateList">
      <el-table-column label="门店" slot="storeName" class="store-name" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.storeName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="门店地址" slot="storeAddress" class="store-name" align="center">
        <template slot-scope="{ row }">
          <el-tooltip :content="row.storeAddress" placement="top">
            <p style="
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                      ">
              {{ row.storeAddress }}
            </p>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="门店邀请人" slot="invStaffName" class="store-name" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.invStaffName || "--" }}<span v-if="row.invStaffPhone">--{{ row.invStaffPhone }}</span> </div>
          <el-tag v-if="row.invStaffIsMajor" size="mini" type="danger" effect="dark" style="margin-left: 5px">
            {{ row.invStaffType == '01' ? '创建人' :
              row.invStaffType == '02' ? '总监' :
                row.invStaffType == '03' ? '店长' : '店员'
            }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="合作状态" slot="applyStatus" class="store-name" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.applyStatus == '01' ? '合作中' : '取消合作' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" slot="operate" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.test004" style="cursor:pointer;color:red;text-decoration:underline;"
            @click="cancelCooperate(row)">取消合作</span>
          <span v-else>需由门店新邀请</span>
        </template>
      </el-table-column>
    </DialogTbale>
    <ChangePushDia ref="changePushDia" @confirm="handleCurrentChangeDialog(1)" />
    <PushRecordDia ref="pushRecordDia" />
  </div>
</template>

<script>
import MinXinRequest from "@/mixins/request";
import _api from "@/utils/request";
import DialogAction from "@/components/common/DialogAction.vue";
import { confirm } from '@/components/common/messagebox'
import ChangePushDia from './dialog/ChangePushDia.vue';
import PushRecordDia from './dialog/PushRecordDia.vue';
import AddSubAccountDia from './dialog/AddSubAccountDia.vue';
import SubAccountDia from './dialog/SubAccountDia.vue';
import MasterAccountDia from './dialog/MasterAccountDia.vue';
export default {
  name: "Table",
  components: { DialogAction, ChangePushDia, PushRecordDia, AddSubAccountDia, SubAccountDia, MasterAccountDia },
  mixins: [MinXinRequest],
  data() {
    return {
      entrepotList: [],
      addspinList: [{
        value: true,
        label: "是",
      },
      {
        value: false,
        label: "否",
      },],
      operateLoading: false,
      operateVisible: false,
      operateTitle: '',
      operateColumns: [
        { slotName: "storeName" },
        { label: "所属商户", prop: "companyName" },
        { slotName: "storeAddress" },
        { slotName: "invStaffName" },
        { slotName: "applyStatus" },
      ],
      dialogOperateList: [],
      operateParams: {
        pageNum: 1,
        total: 0,
        pageSize: 99999,
      },
      tupeIndex: "",
      alltypeData: [],//所有数据
      receiptTypeBtnLoading: false,
      quotePriceType: null,
      formInline: {
        phoneName: "",
      },
      seaformIn: {
        phoneName: "",
      },
      deteleformIn: {
        phoneName: "",
      },
      modelIdsList: [],
      brandIdsList: [],
      deteleIdsList: [],
      modelTypelist: [
        {
          value: "01",
          label: "全部机型",
        },
        {
          value: "02",
          label: "指定报价机型",
        },
        {
          value: "03",
          label: "指定不报价机型",
        },
      ],
      seaPhoneDataList: [],
      detalemodelList: [], //管理已添加机型数据备份
      modelDataList: [], //添加机型数据备份
      seaDataList: [],
      opymodelList: [], //添加机型列表展示
      seaOymodelList: [],
      detepymodelList: [], //管理机型数据展示
      allQuteData: {},
      contactmerchantId: "",
      quotationDialogVisible: false,
      //选择开机机况
      quoteMachineConditioncheckAll: false,
      quoteMachineConditioncheckedCities: [],
      quoteMachineConditioncities: [],
      quoteMachineConditionisIndeterminate: true,
      quoteMachineConditionOptions: [],
      //选择旧机使用情况
      quoteMachineHealthcheckAll: false,
      quoteMachineHealthcheckedCities: [],
      quoteMachineHealthcities: [],
      quoteMachineHealthisIndeterminate: true,
      quoteMachineHealthOptions: [],
      //选择价位段
      quotePriceRangeHealthcheckAll: false,
      quotePriceRangeHealthcheckedCities: [],
      quotePriceRangeHealthcities: [],
      quotePriceRangeHealthisIndeterminate: true,
      quotePriceRangeHealthOptions: [],
      //报价品类配置弹窗
      SetcategoryDloding: false,
      //设置报价机型
      SetquotemodelDloding: false,
      //添加报价机型
      AddquotemodelDloding: false,
      //管理报价机型
      deletequotemodelDloding: false,
      //查看报价机型
      seaquotemodelDloding: false,
      machineTypeId: "", //分类ID
      setionBrandId: "", //单个品牌id
      setionBranddata: {}, //品牌
      savemodelType: "", //分类名称
      copyallmodelData: "", //分类所有数据留底
      qutoding: false,
      // 是否统一使用报价机型配置
      flagDefaultMachine: true,
      // 合作商家批量开启/关闭报价弹框
      bulkCommand: "", //动态口令
      bulkDialogTitle: "",
      bulkDialogShow: false,
      bulkSureShow: false, //批量开启/关闭报价确认弹框
      tableData: [],
      bulkPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      midellData: {},
      middleIngNum: 0, //合作中数量
      companyIds: [], //选中的门店
      // 合作商家弹框新增查询
      unionMercItemList: [
        { slotName: "companyName" },
        { slotName: "cooperationState" },
        { slotName: "Receiver" },
        { slotName: "isMatrixCompany" },
      ],
      companyName: "", //回收商家
      cooperationState: "01", //合作状态
      Receiver: "", //收货方
      isMatrixCompany: "",
      cooperationStateList: [
        {
          name: "合作中",
          id: "01",
        },
        {
          name: "关闭报价",
          id: "02",
        },
        {
          name: "邀请待同意",
          id: "00",
        },
      ],
      ReceiverList: [
        {
          name: "平台",
          id: "02",
        },
        {
          name: "回收商",
          id: "01",
        },
      ],
      // ****************
      // 关联商户
      associationProp: {
        isCode: true,
        isTip: true,
        show: false,
        code: "",
        title: "安全验证",
      },
      // 线上结算渠道
      myMethodsProps: {
        isCode: true,
        show: false,
        code: "",
        title: "",
        codeLabelWidth: "70px",
        currentData: {},
      },
      // 是否可联系店员
      dialogContactStaffTitle: "", //弹框标题 关闭/启用提示
      contactStaffVisible: false, //弹框
      uniformVisible: false, //弹框
      VirtualVisible: false, //弹框
      contactStaffRedText: "",
      isContact: true, //是否可联系店员配，默认启用
      isUniformly: true,//统一报价配置
      isOpenMockQuote:false,
      contactCommand: "", //谷歌动态码
      contactMerchantId: "", //门店商Id
      // 报价设置
      isWaitQuoteJump: true, //待报价是否可跳过订单 true 是 false 否
      setJumpcommand: "",
      setPricemerchantId: 0,
      // **************
      SetWaitQuoteJumpVisible: false, //报价设置弹框
      loading: false,
      dialogLoading: false,
      Remarkstitle: "",
      RemarkslogTxt: "",
      RemarksShow: false,
      RemarksTxt: "",
      disableNote: "",
      formOpenPccommand: "",
      detailedTab: "充值明细",
      detailedShow: false,
      hezuohui: false,
      detailedSearch: {
        staffId: "",
        time: "",
        imei: "",
      },
      staffList: [],
      staffType: "购买人",
      RecomVisible: false,
      slotNameHZ: false,
      isRecommend: "",
      tipTxt: "",
      tipOpenPc: "",
      isOpenPc: "",
      account: {
        acc: "",
        pwd: "",
      },
      acCopycount: {},
      formOpenPc: {
        id: "",
        isOpenPc: "",
        command: "",
      },
      isReset: false,
      pwdFlag: false,
      dialogRecomTitle: "",
      minRedText: "",
      BtnType: "",
      merchantIdType: "",
      bmerchantName: "", //回收商名称
      isNiceMerchant: "", //是否靓机商家true/false
      companyIdType: "",
      seachDataList: [],
      companyId: "",
      tableColumns: [
        { label: "商户ID", prop: "merchantId" },
        { slotName: "merchantName" },
        { label: "商户简称", prop: "shortName" },
        { slotName: "merchantEnterType" },
        { slotName: "accountType" },
        { slotName: "people" },
        { slotName: "operationAddress" },
        { label: "入驻时间", prop: "entryTime" },
        { label: "成交率", prop: "dealRatio" },
        { slotName: "matchServerFeeOnline" },
        { slotName: "operation" },
      ],
      //表格禁用弹窗
      deleteVisible: false,
      total: 0,
      pageNum: 1,
      pageSize: 10,
      DialogDataList: [],
      DialogTableColumns: [],
      title: "",
      //启用禁用弹窗
      dialogVisible: false,
      dialogTitle: "",
      ShowmerchantId: "",
      EnableType: "",
      dialogWidth: 380,
      channelSelectList: [],
      alreadyCompanyList: [],
      detailedType: "01",
      setDialogVisible: false,
      largelyDialogShow: false,//批量创建合作关系
      assignAccDialogVisible: false, //账号分配弹窗
      rulesInfo: {},
      expressList: [],
      closePriceNumber: 0,
      offerDialogShow: false,
      estimatedDialogShow:false,
      erpDialogShow:false,
      AccountLimitDialogShow:false,
      HealthCodeCusdata:{},
      offerDialogBtnLoading: false,
      relationRow: {},
      currentRow: {},
      procedures: "",
      command: "",
      automatedDloding: false,
      createStoreList: [],
      selectedStoreList: [],
      ProvinceList: [],
      cityList: [],
      compydataList: [],
      createInline: {
        companyId: "",
        provinceId: "",
        cityId: ""
      },
      matrixValue: "",
      AdditivematrixOption: [
        { label: "全部", value: "00" },
        { label: "是", value: true },
        { label: "否", value: false },
      ],
      merchantInMatrixStateOptions: [
        { label: "未添加矩阵", value: "01" },
        { label: "合作中（可能重复报价）", value: "02" },
        { label: "关闭报价", value: "03" },
      ],
      sortOption: [
        { label: "全部", value: "" },
        { label: "后台", value: "01" },
        { label: "前端", value: "02" },
      ],
      currentPage4: 1,
      faultTypeList: {
        total: 0
      },
      juMatrixList: [],
      matrixId: "",
      allmerchantData: {},
      unitySelectList: [],
      unitsNumber: 0,
      isBatchprocess: false,
      importVisible: false,
      paramsExcel: {},
      importForm: {
        fileName: "",
      },
      importRules: {
        fileName: [
          {
            required: true,
            message: "仅支持.xls及.xlsx格式,单次上传大小不超过5M",
            trigger: "blur",
          },
        ],
      },
      exportLoading: false,
    };
  },
  created() {
    this.handleCurrentChange();
    this.getMatchChannels();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
    type() {
      return this.pwdFlag ? "password" : "text";
    },
    elIcon() {
      return this.pwdFlag ? "el-icon-minus" : "el-icon-view";
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.handleCurrentChange();
    },
  },
  methods: {
    changeSlave(row, isSlaveAccount) {
      confirm({
        title: '操作提示',
        desc: isSlaveAccount ? '说明：启用后可在列表处添加或管理从账户' : '说明：禁用后，该商户下的从账户将自动解除关联',
        message: isSlaveAccount ? '是否确认启用从账户管理？' : '是否禁用从账户管理？',
        confirm: () => {
          _api.ocAccountSlave({
            merchantId: row.merchantId,
            isSlaveAccount
          }).then(() => {
            this.$message.success('操作成功！')
            this.handleCurrentChange(this.pageNum);
          })
        }
      })
    },
     //获取仓库
     getwarehouseSelectList() {
      _api.getwarehouseSelectList().then(res => {
        this.entrepotList = res.data
      })
    },
    // 弹窗关闭
    exportinDialogClosed() {
      if (this.$refs["addForm"]) {
        this.$refs["addForm"].resetFields();
      } else {
        this.$refs["importForm"].resetFields();
      }
      this.importForm = {
        fileName: "",
      };
      this.$refs.fileUpload.value = "";
      this.paramsExcel = {};
    },
    //表格清理
    fileClear() {
      this.$refs.fileUpload.value = "";
      this.paramsExcel = {};
    },
    onUploadList(event, type) {
      console.log(event);
      this.paramsExcel.file = event.target.files;
      if (this.paramsExcel.file.length) {
        this.paramsExcel.excelName = event.target.files[0].name;
        this.importForm.fileName = event.target.files[0].name;
      }
    },
    // 确定导出
    uploadFile() {
      if (!this.paramsExcel.file || this.paramsExcel.file.length == 0) {
        return this.$message.error("请上传表格");
      }
      const fd = new FormData();
      console.log(this.paramsExcel);
      fd.append("file", this.paramsExcel.file[0]); //文件
      fd.append("filterMachineModelIds", this.setionBranddata.assignMachineIds); //文件
      fd.append("brandId", this.setionBranddata.id); //文件
      fd.append("machineTypeId", this.machineTypeId); //文件
      console.log(fd);
      this.repeatData = [];
      // 发起请求
      _api
        .importAssignMachine(fd)
        .then((res) => {
          console.log("_RequestUploads_", res);
          if (res.code === 1) {
            this.importForm.fileName = "";
            this.$refs.fileUpload.value = "";
            if (res.data.length > 0) {
              let modids = [];
              res.data.forEach((item) => {
                modids.push(item.id);
              });
              this.seaPhoneDataList.forEach((item) => {
                if (this.setionBrandId === item.id) {
                  item.assignMachineIds.push(...modids);
                  let resetdata= new Set(item.assignMachineIds)
                  item.assignMachineIds=[...resetdata]
                }
              });
              this.$message({
                type: "success",
                message: "导入成功！",
              });
            } else {
              this.$message({
                type: "warning",
                message: "导入机型为空！",
              });
            }
            this.importVisible = false;
          } else {
            this.$message({
              type: "warning",
              message: res.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.importForm.fileName = "";
          this.$refs.fileUpload.value = "";
        });
    },
    //下载模板
    download(data) {
      let params = {
        "assignMachineIdList": null,
        "brandId": this.setionBranddata.id,
        "machineTypeId": this.machineTypeId,
      }
      if(data==1){
        console.log(data)
        params.assignMachineIdList=this.setionBranddata.assignMachineIds
      }
      this.exportLoading = true;
      _api
        .importModelByTypeBrand(params)
        .then((res) => {
          console.log(res);
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                this.$message.error(data.msg || "导出失败");
              }
            } catch (err) {
              const fileName = "指定机型列表.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8"); // 设置读取的数据以及返回的数据类型为utf-8
          this.codeLoading = false;
          this.exportLoading = false;
        })
        .catch((err) => {
          this.exportLoading = false;
          console.log(err);
          this.codeLoading = false;
        });
    },
    unitySelectionChange(e) {
      console.log(e)
      this.unitySelectList = e
    },
    Batchunification() {
      if (this.unitySelectList.length) {
        this.isBatchprocess = true
        this.isUniformly = true
        this.uniformVisible = true;
        this.unitsNumber = this.unitySelectList.length
      }
    },
    //批量操作虚拟报价
    Virtualfication(type) {
      if (this.unitySelectList.length) {
        if(type=='on'){
          this.isOpenMockQuote = true
        }else{
          this.isOpenMockQuote = false
        }
        this.isBatchprocess = true
        this.VirtualVisible = true;
        this.unitsNumber = this.unitySelectList.length
      }
    },
    statusClick(type) {
      this.cooperationState = type
      this.listCompany(1);
    },
    //批量添加分转
    appauxiliary(row) {
      this.allmerchantData = row
      // 矩阵下拉
      this.getSelectMatrixList()
      // 门店商家下拉
      this.getMerchantList()
      // 获取省市区
      this.getProvince()
      this.matrixValue = ""
      this.matrixId = ""
      this.createInline = {
        companyId: "",
        provinceId: "",
        cityId: ""
      },
      this.storeCurrentChange(1)
      this.getwarehouseSelectList()
      this.automatedDloding = true
    },
    //搜索
    serchvoluntarilySubmit() {
      this.storeCurrentChange(1)
    },
    // 矩阵下拉
    getSelectMatrixList() {
      _api.getSelectMatrixList().then(res => {
        this.juMatrixList = res.data;
      });
    },
    //Q-未合作门店商家【获取】
    storeCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      let teamworkParams = {
        "cityId": this.createInline.cityId,
        "companyId": this.createInline.companyId,
        matrixId: this.matrixId,
        isInMatrix: this.matrixValue,
        "pageNum": val,
        "pageSize": 10,
        merchantId: this.allmerchantData.merchantId,
        "provinceId": this.createInline.provinceId,
      }
      _api.listCompanyUnMiddle(teamworkParams).then(res => {
        this.createStoreList = res.data.records;
        this.faultTypeList = res.data;
      })
    },
    // 门店商家下拉
    getMerchantList() {
      _api.getSelectList().then((res) => {
        if (res.code === 1) {
          this.compydataList = res.data.map((v) => {
            return {
              label: v.name,
              value: v.id,
            };
          });
        }
      })
    },
    // 获取省市区
    getProvince() {
      _api.listAudit(0).then(r => {
        this.ProvinceList = r.data.map(v => {
          return {
            label: v.cityName,
            value: v.id
          }
        })
      })
    },
    selectProvince(e) {
      console.log(e);
      if (e) {
        this.createInline.cityId = ""
        this.getCity(e)
      } else {
        this.createInline.cityId = ""
        this.cityList = []
      }
    },
    // 获取市区
    getCity(v) {
      _api.listAudit(v).then(r => {
        console.log(v);
        this.cityList = r.data.map(v => {
          return {
            label: v.cityName,
            value: v.id
          }
        })
      })
    },
    zdmodelleSelect(val) {
      console.log(val)
      this.selectedStoreList.unshift(...val)
      //去重后加入
      const uniqueArr = this.selectedStoreList.filter(
        (item, index) =>
          this.selectedStoreList.findIndex(
            (i) => i.id === item.id
          ) === index
      );
      this.selectedStoreList = uniqueArr;
      console.log(this.selectedStoreList)
    },
    //删除
    detaleCompy(row) {
      this.selectedStoreList = this.selectedStoreList.filter(item => item.id !== row.id)
    },
    //是否可添加分转
    AddDptmfenzhuan(e, data) {
      console.log(e, data)
      // 同步处理生效时间
      data.isImmediate = e
    },
    //批量创建合作
    automatedSubmit() {
      if (this.selectedStoreList.length == 0) {
        return this.$message.error("请选择需要合作门店！");
      }
      this.largelyDialogShow = true
    },
    largeSure() {
      if (!this.command) {
        return this.$message.error("请输入谷歌动态码！");
      }
      if (!this.procedures) {
        return this.$message.error("请选择手续费承担方！");
      }
      let Unionpram = {
        "command": this.command,
        "companyList": [],
        "merchantId": this.allmerchantData.merchantId,
        "processFeeBear": this.procedures
      }
      Unionpram.companyList = this.selectedStoreList.map(item => {
        return {
          "companyId": item.id,
          "effectiveTime": null,
          "isAddDptm": item.isAddDptm,
          "isImmediate": item.isImmediate,
          "warehouseId": item.warehouseId
        }
      })
      console.log(Unionpram)
      this.offerDialogBtnLoading = true
      _api.allsaveMerchantUnionBatch(Unionpram).then(res => {
        if (res.code == 1) {
          this.command = ""
          this.procedures = ""
          this.largelyDialogShow = false
          this.automatedDloding = false
          this.handleCurrentChange();
          this.offerDialogBtnLoading = false
        } else {
          this.offerDialogBtnLoading = false
        }
      }).catch(err => {
        this.offerDialogBtnLoading = false
      })
    },
    //管理已添加机型
    shipMachine(row) {
      this.deteleformIn.phoneName = "";
      console.log(row.assignMachineIds);
      this.setionBrandId = row.id;
      this.setionBranddata = row
      let addparams = {
        assignMachineModelIds: row.assignMachineIds,
        brandId: row.id,
        type: "02", //类型（全部：00,未添加：01，已添加：02）
        machineTypeId: this.machineTypeId,
      };
      //查看需添加机型
      _api.listByTypeBrand(addparams).then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.deletequotemodelDloding = true;
          this.detalemodelList = JSON.parse(JSON.stringify(res)).data;
          this.detepymodelList = JSON.parse(JSON.stringify(res)).data;
        }
      });
    },
    //导入机型
    reportitivemodel(row) {
      console.log(row);
      this.setionBrandId = row.id;
      this.setionBranddata = row
      this.importVisible = true
    },
    //添加机型
    Additivemodel(row) {
      this.setionBrandId = row.id;
      this.setionBranddata = row
      let addparams = {
        assignMachineModelIds: row.assignMachineIds,
        brandId: row.id,
        type: "01", //类型（全部：00,未添加：01，已添加：02）
        machineTypeId: this.machineTypeId,
      };
      //查看需添加机型
      _api.listByTypeBrand(addparams).then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.AddquotemodelDloding = true;
          this.modelDataList = JSON.parse(JSON.stringify(res)).data;
          this.opymodelList = JSON.parse(JSON.stringify(res)).data;
        }
      });
    },
    switchTrigin(type) {
      this.quotePriceType = type;
    },
    //设置报价机型
    Setquemodel(data, index) {
      //保存分类
      this.tupeIndex = index
      this.savemodelType = data.type;
      console.log(data);
      this.seaPhoneDataList = data.brands;
      this.machineTypeId = data.id;
      this.SetquotemodelDloding = true;
      this.qutoding = true;
      // 回显选中品牌
      this.$nextTick(() => {
        this.seaPhoneDataList.forEach((item) => {
          if (item.selected) {
            this.$refs.qotemultipleTable.toggleRowSelection(item, true);
          }
        });
      });
      this.$forceUpdate();
      setTimeout(() => {
        this.qutoding = false;
      }, 800);
    },
    //报价机型模糊查询
    serchModalSubmit() {
      console.log(this.formInline.phoneName);
      this.opymodelList = [];
      if (this.formInline.phoneName) {
        this.modelDataList.forEach((item) => {
          if (item.name.toLowerCase().includes(this.formInline.phoneName.toLowerCase())) {
            this.opymodelList.push(item);
          }
        });
      } else {
        this.opymodelList = this.modelDataList;
      }
    },
    //查看报价机型查看
    SeamodelSubmit() {
      console.log(this.seaformIn.phoneName);
      this.seaOymodelList = [];
      if (this.seaformIn.phoneName) {
        this.seaDataList.forEach((item) => {
          if (item.phnoeName.toLowerCase().includes(this.phnoeName.phoneName.toLowerCase())) {
            this.seaOymodelList.push(item);
          }
        });
      } else {
        this.seaOymodelList = this.seaDataList;
      }
    },
    //删除报价机型模糊查询
    deteleserchSubmit() {
      console.log(this.deteleformIn.phoneName);
      this.detepymodelList = [];
      if (this.deteleformIn.phoneName) {
        this.detalemodelList.forEach((item) => {
          if (item.name.toLowerCase().includes(this.deteleformIn.phoneName.toLowerCase())) {
            this.detepymodelList.push(item);
          }
        });
      } else {
        this.detepymodelList = this.detalemodelList;
      }
    },
    //删除机型
    detaleRedyphone(id) {
      //删除展示数据
      this.detepymodelList.forEach((item, index) => {
        if (id === item.id) {
          this.detepymodelList.splice(index, 1);
        }
      });
      //同步此分类总数据
      this.seaPhoneDataList.forEach((item) => {
        if (this.setionBrandId === item.id) {
          item.assignMachineIds.forEach((itemhave, haveindex) => {
            if (itemhave === id) {
              item.assignMachineIds.splice(haveindex, 1);
            }
          });
        }
      });
      this.deletequotemodelDloding = false;
    },
    //批量删除机型
    detaleAllphone() {
      //删除展示数据
      this.deteleIdsList.forEach((item) => {
        this.detepymodelList.forEach((items, index) => {
          if (item.id === items.id) {
            this.detepymodelList.splice(index, 1);
          }
        });
      });
      //同步此分类总数据
      this.seaPhoneDataList.forEach((item) => {
        if (this.setionBrandId === item.id) {
          this.deteleIdsList.forEach((itemde) => {
            item.assignMachineIds.forEach((itemhave, haveindex) => {
              if (itemhave === itemde.id) {
                item.assignMachineIds.splice(haveindex, 1);
              }
            });
          });
        }
      });
      this.deletequotemodelDloding = false;
    },
    //管理机型
    detelemodelSubmit() {
      this.deletequotemodelDloding = false;
    },
    //选择已选管理机型
    deteleModeleSelect(data) {
      console.log(data);
      this.deteleIdsList = data;
    },
    modelchange(e) {
      console.log(e);
    },
    //取消保存设置报价机型
    cloSmephone() {
      this.$nextTick(() => {
        this.$refs.qotemultipleTable.clearSelection();
      });
      this.SetquotemodelDloding = false;
    },
    //保存设置报价机型
    SetquotemodelSubmit() {
      console.log(this.brandIdsList);
      //更新外层数据
      this.seaPhoneDataList.forEach((item) => {
        item.selected = false;
      });
      this.brandIdsList.forEach((item) => {
        this.seaPhoneDataList.forEach((itemsd) => {
          if (item.id === itemsd.id) {
            itemsd.selected = true;
          }
        });
      });
      let actionARR = []
      actionARR = this.seaPhoneDataList.filter(item => item.selected)
      console.log(this.seaPhoneDataList, this.tupeIndex);
      if (actionARR.length !== this.seaPhoneDataList.length) {
        this.allQuteData.typeList[this.tupeIndex].selected = false
      } else {
        this.allQuteData.typeList[this.tupeIndex].selected = true
      }

      this.$nextTick(() => {
        this.$refs.qotemultipleTable.clearSelection();
      });
      this.$forceUpdate();
      console.log(this.allQuteData);
      this.SetquotemodelDloding = false;
    },
    //添加报价机型
    AddmodelSubmit() {
      if (this.modelIdsList.length === 0) {
        return this.$message.error("请选择报价机型！");
      }
      let modids = [];
      this.modelIdsList.forEach((item) => {
        modids.push(item.id);
      });
      this.seaPhoneDataList.forEach((item) => {
        if (this.setionBrandId === item.id) {
          item.assignMachineIds.push(...modids);
        }
      });
      this.formInline.phoneName = "";
      this.AddquotemodelDloding = false;
    },
    //批量选择品牌
    handleSelect(data) {
      console.log(data);
      this.brandIdsList = data;
    },
    //批量选择机型
    modelleSelect(data) {
      console.log(data);
      this.modelIdsList = data;
      // console.log(this.modelIdsList);
    },
    /***8*88888 */
    //设置报价品类
    setQuotecategory(row) {
      this.contactmerchantId = row.merchantId;
      // 是否统一使用报价机型配置
      this.flagDefaultMachine = row.isDefaultQuoteMachine;
      this.getQuoteAssignTemp();
    },
    //获取报价配置模板
    getQuoteAssignTemp() {
      //清空
      this.resetalllist();
      this.alltypeData = []
      _api
        .getmerchantQuoteAssignTemp({
          merchantId: this.contactmerchantId, //用关联商家的
        })
        .then((res) => {
          this.alltypeData = res.data.typeList
          //数据留底
          this.copyallmodelData = JSON.parse(JSON.stringify(res)).data;
          this.allQuteData = res.data;
          this.quotePriceType = res.data.quotePriceType;
          //选择报价机况
          this.quoteMachineConditioncities = res.data.quoteMachineCondition;
          res.data.quoteMachineCondition.forEach((item) => {
            this.quoteMachineConditionOptions.push(item.value);
            if (item.selected) {
              this.quoteMachineConditioncheckedCities.push(item.value);
            }
          });
          //选择旧机使用情况
          this.quoteMachineHealthcities = res.data.quoteMachineHealth;
          res.data.quoteMachineHealth.forEach((item) => {
            this.quoteMachineHealthOptions.push(item.value);
            if (item.selected) {
              this.quoteMachineHealthcheckedCities.push(item.value);
            }
          });
          //选择价位段
          this.quotePriceRangeHealthcities = res.data.quotePriceRange;
          res.data.quotePriceRange.forEach((item) => {
            this.quotePriceRangeHealthOptions.push(item.value);
            if (item.selected) {
              this.quotePriceRangeHealthcheckedCities.push(item.value);
            }
          });
          this.SetcategoryDloding = true;
        });
    },
    //清空报价品类
    resetalllist() {
      //选择报价机况
      this.quoteMachineConditioncheckAll = false;
      this.quoteMachineConditioncheckedCities = [];
      this.quoteMachineConditioncities = [];
      this.quoteMachineConditionisIndeterminate = true;
      this.quoteMachineConditionOptions = [];
      //选择报价机况
      this.quoteMachineHealthcheckAll = false;
      this.quoteMachineHealthcheckedCities = [];
      this.quoteMachineHealthcities = [];
      this.quoteMachineHealthisIndeterminate = true;
      this.quoteMachineHealthOptions = [];
      //选择价位段
      this.quotePriceRangeHealthcheckAll = false;
      this.quotePriceRangeHealthcheckedCities = [];
      this.quotePriceRangeHealthcities = [];
      this.quotePriceRangeHealthisIndeterminate = true;
      this.quotePriceRangeHealthOptions = [];
    },
    //确认保存报价配置
    quotationSubmit() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
      console.log(this.allQuteData, "alltypeData", this.alltypeData);
      let QuoteConfigparams = {
        assignQuoteMachineCondition: [], //	指定报价旧机机况
        assignQuoteMachineHealth: [], //指定报价旧机使用情况
        assignQuotePriceRange: [], //指定价格段
        command: this.command, //谷歌验证码
        merchantId: this.contactmerchantId, //用关联商家的
        quotePriceType: this.quotePriceType, //价格使用 01 按靓机价 02 按预估价
        typeList: JSON.parse(JSON.stringify(this.allQuteData)).typeList,
      };
      //指定报价旧机使用情况
      console.log(this.quoteMachineConditioncheckedCities);
      this.quoteMachineHealthcheckedCities.forEach((itema) => {
        this.quoteMachineHealthcities.forEach((itemb) => {
          if (itema == itemb.value) {
            QuoteConfigparams.assignQuoteMachineHealth.push(itemb.key);
          }
        });
      });
      //	指定报价旧机机况
      this.quoteMachineConditioncheckedCities.forEach((itema) => {
        this.quoteMachineConditioncities.forEach((itemb) => {
          if (itema == itemb.value) {
            QuoteConfigparams.assignQuoteMachineCondition.push(itemb.key);
          }
        });
      });
      //指定价格段
      this.quotePriceRangeHealthcheckedCities.forEach((itema) => {
        this.quotePriceRangeHealthcities.forEach((itemb) => {
          if (itema == itemb.value) {
            QuoteConfigparams.assignQuotePriceRange.push(itemb.key);
          }
        });
      });
      //没选中的品牌保存时删除
      QuoteConfigparams.typeList.forEach(irb => {
        irb.brands = irb.brands.filter(item => item.selected)
      })
      console.log(QuoteConfigparams);
      this.receiptTypeBtnLoading = true;
      // 保存合作报价配置
      _api
        .saveMerchantMiddleQuoteConfig(QuoteConfigparams)
        .then((res) => {
          this.receiptTypeBtnLoading = false;
          this.quotationDialogVisible = false;
          this.SetcategoryDloding = false;
          this.command = "";
          this.$message({
            message: "配置成功！",
            type: "success",
          });
          this.handleCurrentChange(this.pageNum);
        })
        .catch((ree) => {
          this.receiptTypeBtnLoading = false;
        });
    },

    //保存合作报价配置
    SetcategorySubmit() {
      if (!this.quoteMachineHealthcheckedCities.includes("其他")) {
        this.$message.error("旧机使用情况必须选择其他选项");
        return;
      }
      let phnoArr = []
      let isQITA = true
      this.allQuteData.typeList.forEach(item => {
        if (item.name == '手机') {
          phnoArr = item.brands
        }
      })
      console.log(phnoArr);
      if (phnoArr.length === 0) {
        console.log("不用判断");
      } else {
        phnoArr.forEach(its => {
          if (its.id === -1 && !its.selected) {
            isQITA = false
          }
        })
        if (this.quoteMachineConditioncheckedCities.includes("功能机/老年机") && !isQITA) {
          this.$message.error(
            "报价机况有功能机/老年机的,手机品牌必须选择其他选项！"
          );
          return;
        }
      }
      this.quotationDialogVisible = true;
    },
    //选择报价机况
    quoteMachineConditionallChange(val) {
      console.log(val);
      if (!this.mobilecheckedCities.includes("其他") && val) {
        this.mobilecheckedCities.push("其他");
      }
      this.quoteMachineConditioncheckedCities = val
        ? this.quoteMachineConditionOptions
        : [];
      this.quoteMachineConditionisIndeterminate = false;
    },
    quoteMachineConditionChange(value) {
      console.log(value);
      if (
        value[0] == "功能机/老年机" &&
        !this.mobilecheckedCities.includes("其他")
      ) {
        this.mobilecheckedCities.push("其他");
      }
      let quoteMachineConditionCount = value.length;
      this.quoteMachineConditioncheckAll =
        quoteMachineConditionCount === this.quoteMachineConditioncities.length;
      this.quoteMachineConditionisIndeterminate =
        quoteMachineConditionCount > 0 &&
        quoteMachineConditionCount < this.quoteMachineConditioncities.length;
    },
    //选择旧机使用情况
    quoteMachineHealthallChange(val) {
      console.log(val);
      this.quoteMachineHealthcheckedCities = val
        ? this.quoteMachineHealthOptions
        : [];
      this.quoteMachineHealthisIndeterminate = false;
    },
    quoteMachineHealthChange(value) {
      console.log(value);
      let quoteMachineHealthCount = value.length;
      this.quoteMachineHealthcheckAll =
        quoteMachineHealthCount === this.quoteMachineHealthcities.length;
      this.quoteMachineHealthisIndeterminate =
        quoteMachineHealthCount > 0 &&
        quoteMachineHealthCount < this.quoteMachineHealthcities.length;
    },
    //选择价位段
    quotePriceRangeHealthallChange(val) {
      console.log(val);
      this.quotePriceRangeHealthcheckedCities = val
        ? this.quotePriceRangeHealthOptions
        : [];
      this.quotePriceRangeHealthisIndeterminate = false;
    },
    quotePriceRangeHealthChange(value) {
      console.log(value);
      let quoteMachineHealthCount = value.length;
      this.quotePriceRangeHealthcheckAll =
        quoteMachineHealthCount === this.quotePriceRangeHealthcities.length;
      this.quotePriceRangeHealthisIndeterminate =
        quoteMachineHealthCount > 0 &&
        quoteMachineHealthCount < this.quotePriceRangeHealthcities.length;
    },
    /***8*88888 */
    // 合作商家批量开启/关闭报价弹框
    // 打开弹框
    bulkQuote(type) {
      if (type == "open") {
        //开启
        console.log(type, "open");
        this.bulkDialogTitle = "开启";
        this.bulkDialogShow = true;
      } else {
        //关闭
        console.log(type, "close");
        this.bulkDialogTitle = "关闭";
        this.bulkDialogShow = true;
      }
      this.bulkPage.pageNum = 1
      this.bulkCurrentChangeDialog();
      this.companyIds = [];
      this.tableData = [];
      this.bulkCommand = ''
      console.log(this.companyIds, "this.companyIds");
    },
    // 批量开启/关闭数据请求
    bulkCurrentChangeDialog(val) {
      console.log(val, "sssssssss");
      console.log(val);
      if (val) {
        this.bulkPage.pageNum = val;
      }
      this.bulkLoading = true;
      let middleStatus = this.bulkDialogTitle === "开启" ? '02' : '01'
      // 批量开启/关闭参数
      let params = {
        pageNum: this.bulkPage.pageNum,
        pageSize: this.bulkPage.pageSize,
        middleStatus: middleStatus,
        merchantId: this.merchantIdType,
      }
      _api.listCompany(params).then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.midellData = res.data
          this.tableData = res.data.ipage.records;
          this.bulkPage.pageNum = res.data.ipage.current;
          this.bulkPage.total = res.data.ipage.total;
          this.middleIngNum = res.data.middleIngNum;
        }
        this.bulkLoading = false;
        console.log(this.tableData, res.data.records, "00000000000000");
      });
    },
    // 多选
    bulkHandleSelect(val) {
      console.log(val, "val88888");
      this.companyIds = val.map((obj) => obj.id);
      console.log(this.companyIds, "this.companyIds哈哈哈哈哈");
    },
    // 全选
    bulkHandleSelectAll(val) {
      console.log(val, "val9999");
      this.companyIds = val.length ? val.map((obj) => obj.id) : null;
      console.log(this.companyIds, "this.companyIds哈999");
    },
    // 关闭
    bulkDialogClosed() {
      this.bulkDialogShow = false;
      this.companyIds = [];
    },
    // 确定
    bulkDialogSuccess() {
      this.bulkSureShow = true;
      if (this.bulkDialogTitle == "关闭") {
        _api.closeQuoteQueryMaxQuoteNum({
          "companyIds": this.companyIds,
          "merchantId": this.merchantIdType
        }).then(res => {
          this.closePriceNumber = res.data
        })
      }
    },
    // 确认弹框确认--请求*****
    bulkSureSuccess() {
      if (!this.bulkCommand) {
        return this.$message.error("请输入动态口令");
      }
      if (!this.companyIds.length) {
        return this.$message.error("请选择商家");
      }
      console.log(this.companyIds, "hhh吼吼吼");
      let params = {
        merchantId: this.merchantIdType,
        command: this.bulkCommand,
        companyIds: this.companyIds,
      };
      // 批量开启
      if (this.bulkDialogTitle === "开启") {
        _api.merchantUnionBatch(params).then((res) => {
          if (res.code === 1) {
            this.bulkSureShow = false;
            this.bulkDialogShow = false;
            this.$message({
              message: res.msg || "操作成功",
              type: "success",
            });
            this.companyIds = [];
            this.bulkCommand = "";
            this.listCompany();
          }
        });
      } else {
        //批量关闭
        _api.closeQuoteBatch(params).then((res) => {
          if (res.code === 1) {
            this.bulkSureShow = false;
            this.bulkDialogShow = false;
            this.$message({
              message: res.msg || "操作成功",
              type: "success",
            });
            this.companyIds = [];
            this.bulkCommand = "";
            this.listCompany();
          }
        });
      }
    },
    // **************************
    myMethodsConfig() {
      const { code } = this.myMethodsProps;
      if (!code) {
        return this.$message.error("请输入动态口令");
      }
      const {
        merchantId,
        matchServerFeeOnline,
      } = this.myMethodsProps.currentData;
      _api
        .setMatchServerFeeOnline({
          command: code,
          matchServerFeeOnline: matchServerFeeOnline ? "01" : "02",
          merchantId,
        })
        .then((r) => {
          this.myMethodsProps = {
            ...this.myMethodsProps,
            show: false,
            code: "",
          };
          this.handleCurrentChange(this.page.pageNum);
        });
    },
    AccountLimitcont(row){
      this.HealthCodeCusdata=row
      this.AccountLimitDialogShow=true
    },
    //
    estimatedMethodsSwitch(e,row){
      console.log(e,row)
      this.HealthCodeCusdata=row
      this.estimatedDialogShow=true
    },
    //同步erp数据
    ErpMethodsSwitch(e,row){
      console.log(e,row)
      this.HealthCodeCusdata=row
      this.erpDialogShow=true
    },
    // 线上结算渠道开关
    myMethodsSwitch(e, row) {
      console.log(row);
      if (e) {
        this.myMethodsProps = {
          ...this.myMethodsProps,
          title: "开启提示",
          currentData: row,
          state: e,
          code: "",
          show: true,
        };
      } else {
        this.myMethodsProps = {
          ...this.myMethodsProps,
          title: "关闭提示",
          currentData: row,
          state: e,
          code: "",
          show: true,
        };
      }
    },
    // 关联商户
    associationStore(row) {
      this.associationProp = {
        ...this.associationProp,
        show: true,
        code: "",
        cuurentData: row,
        tipText: "是否确定将该商户标记为矩阵商户",
      };
    },
    // 确定
    associationPropConfig() {
      if (!this.associationProp.code) {
        return this.$message.error("请输入动态口令");
      }
      _api
        .setMerchantIsMatrix(
          this.associationProp.code,
          this.associationProp.cuurentData.merchantId
        )
        .then((r) => {
          this.$message.success("操作成功");
          this.handleCurrentChange();
          this.associationProp = {
            ...this.associationProp,
            show: false,
          };
        });
    },
    // 查看报价设置
    seeSetWaitQuoteJump(row) {
      (this.SetWaitQuoteJumpVisible = true),
        (this.isWaitQuoteJump = row.isWaitQuoteJump),
        (this.setPricemerchantId = row.merchantId);
    },
    // 报价设置确认
    SetJumpSuccess() {
      let params = {
        command: this.setJumpcommand,
        isWaitQuoteJump: this.isWaitQuoteJump,
        merchantId: this.setPricemerchantId,
      };
      _api.setWaitQuoteJump(params).then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.SetWaitQuoteJumpVisible = false;
          this.$message.success(res.msg);
          this.handleCurrentChange();
        } else {
          this.$message.danger(res.msg);
        }
      });
    },
    Remarkstwo(row) {
      this.Remarkstitle = "修改备注";
      this.RemarksShow = true;
      this.RemarkslogTxt = row.masterNote;
      this.merchantId = row.merchantId;
    },

    //操作备注按钮
    Remarks(row) {
      this.Remarkstitle = "添加备注";
      console.log(row, "备注");
      this.RemarksShow = true;
      (this.merchantId = row.merchantId), (this.RemarkslogTxt = "");
    },
    //备注弹窗取消
    Remarks_log_cl() {
      this.RemarksShow = false;
    },
    //备注弹窗确认
    Remarks_log_sure() {
      _api
        .modifyMerchantListOper({
          masterNote: this.RemarkslogTxt,
          merchantId: this.merchantId,
          type: "03",
        })
        .then((res) => {
          if (res.code == 1) {
            this.$message.success(res.msg || "操作成功!");
            this.RemarksShow = false;
          } else {
            this.$message.danger(res.msg || "操作失败!");
          }
          console.log(res);
        });
    },

    // 表格切换页面
    handleCurrentChange(val) {
      this.loading = true;
      console.log("表格切换页面", val);
      const params = {
        merchantType: "02",
        merchantName: this.SeachParams.merchantName,
        contactPhone: this.SeachParams.contactPhone,
        startTime: this.SeachParams.startTime,
        merchantId: this.SeachParams.merchantId,
        endTime: this.SeachParams.endTime,
        isRecommend: this.SeachParams.isRecommend,
        channel: this.SeachParams.channel,
        companyId: 0,
        pageNum: val,
        pageSize: this.pageSize,
        cityId: this.SeachParams.cityId,
        provinceId: this.SeachParams.provinceId,
        shortName: this.SeachParams.shortName,
        matchServerFeeOnline: this.SeachParams.matchServerFeeOnline,
        merchantEnterType: this.SeachParams.merchantEnterType,
      };
      _api.ShopTable(params).then((res) => {
        if (res.code === 1) {
          console.log(res.data.records);
          this.seachDataList = res.data.records.map((v) => {
            return {
              ...v,
              matchServerFeeOnline:
                v.matchServerFeeOnline === "01" ? true : false,
            };
          });
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.merchantId = res.data.records.merchantId;
        }
        this.loading = false;
        
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    handleCurrentChangeDialog(val) {
      this.DialogDataList = [];
      this.pageNum = val;
      console.log(this.BtnType);
      this.dialogLoading = true;
      if (this.BtnType === "orderCount") {
        this.recycleMerchantOrderList();
      } else if (this.BtnType === "Number") {
        this.recycleChantList();
      } else if (this.BtnType === "staffCount") {
        this.listStaff();
      } else if (this.BtnType === "business") {
        this.listCompany();
      } else if (this.BtnType === "remainScanTimes") {
        if (this.detailedTab == "充值明细") {
          this.payList();
        } else if (this.detailedTab == "收货使用明细") {
          this.rechargeList("03");
        } else if (this.detailedTab == "苹果锁使用明细") {
          this.rechargeList("04");
        } else if (this.detailedTab == "绑码使用明细") {
          this.rechargeList("07");
        } else if (this.detailedTab == "退回明细") {
          this.rechargeList("08");
        } else if (this.detailedTab == "撮合扣款明细") {
          this.matchOrderDetail();
        } else if (this.detailedTab == "串号查询使用明细") {
          this.rechargeList("09");
        } else if (this.detailedTab == "下账明细") {
          this.rechargeList("10");
        }
      }
    },
    // 表格操作
    handleBtn(row, type) {
      this.ShowmerchantId = row.merchantId;
      //编辑
      if (type === "edit") {
        this.$router.push({
          path: "/merchant/add",
          query: { type: "edit", id: row.merchantId },
        });
      }
      //启用
      else if (type === "success") {
        this.disableNote = "";
        this.formOpenPccommand = "";
        this.dialogVisible = true;
        this.dialogTitle = "启用提示";
        this.EnableType = "success";
      }
      //禁用
      else if (type === "danger") {
        this.disableNote = "";
        this.formOpenPccommand = "";
        this.dialogVisible = true;
        this.dialogTitle = "禁用提示";
        this.EnableType = "danger";
      }
    },
    handleSwitch(row) {
      this.ShowmerchantId = row.merchantId;
      console.log(row, "是否推荐");
      if (row.isRecommend) {
        (this.dialogRecomTitle = "开启"),
          (this.minRedText =
            "说明：开启推荐后，门店端小程序添加回收商家时自动显示该商家在推荐列表。");
      } else {
        (this.dialogRecomTitle = "关闭"),
          (this.minRedText =
            "说明：关闭推荐后，门店端小程序添加回收商家时将不会推荐显示该商家在推荐列表，仅可通过搜索查询。");
      }
      this.isRecommend = row.isRecommend;
      this.RecomVisible = true;
    },
    // 回收商管理后台开关
    handleRecyclerSwitch(row) {
      console.log("~~~", row);
      this.dialogTitle = "提示";
      this.tipTxt = "启用后须分配账号给回收商创建者，才可使用系统。";
      if (row.isOpenPc) {
        this.tipOpenPc = "启用";
      } else {
        this.tipOpenPc = "禁用";
      }
      this.formOpenPc.id = row.merchantId;
      this.formOpenPc.isOpenPc = row.isOpenPc;
      this.assignAccDialogVisible = true;
    },
    // 分配账号
    branchAcc(row) {
      console.log("===", row);
      this.dialogTitle = row.isAssignAccount ? "查看账号" : "分配账号";
      if (row.isAssignAccount) {
        this.dialogTitle = "查看账号";
        let acobj={
          acc: row.userAcc,
          pwd: "fx" + row.contactPhone.slice(-8),
        };
        //复制数据进行对比
        this.account = JSON.parse(JSON.stringify(acobj))
        this.acCopycount=JSON.parse(JSON.stringify(acobj))
        this.pwdFlag = true;
      } else {
        this.dialogTitle = "分配账号";
        this.account = {
          acc: "",
          pwd: "fx" + row.contactPhone.slice(-8),
        };
      }
      this.tipTxt = "开启回收商家管理后台，分配账号为回收商端创建者。";

      this.assignAccDialogVisible = true;
      this.merchantId = row.merchantId;
    },
    // //弹窗取消
    handleClose() {
      this.RecomVisible = false;
    },
    //弹窗确定
    handleSuccess() {
      let params = {
        merchantId: this.ShowmerchantId,
        isRecommend: this.isRecommend,
        type: "02",
      };
      _api.modifyMerchantListOper(params).then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.$message.success("操作成功");
        } else {
          this.$message.error("操作失败");
        }
      });
      this.RecomVisible = false;
    },
    handleDialogClosed() {
      this.dialogVisible = false;
      this.assignAccDialogVisible = false;
      this.acCopycount={}
      this.isReset=false
      this.handleCurrentChange(this.pageNum);
    },
    // 点击生成账号
    createPcAcc() {
      _api.createRecyclerPcAcc({ id: this.merchantId }).then((res) => {
        if (res.code == 1) {
          this.account.acc = res.msg;
        }
      });
    },
    // 重置账号密码
    reset() {
      this.isReset = true;
      this.pwdFlag = false;
    },
    // 弹框关闭
    receiptTypeClosed() {
      this.command = "";
    },
    // 关闭账号弹窗
    handleClosed() {
      this.account = {
        acc: "",
        pwd: "",
      };
      (this.formOpenPc = {
        id: "",
        isOpenPc: "",
        command: "",
      }),
        (this.pwdFlag = false);
      this.isReset = false;
      this.assignAccDialogVisible = false;
    },
    // 确定分配账号
    handleAccountSuccess() {
      let params = {
        merchantId: this.merchantId,
        userAcc: this.account.acc,
        userPwd: this.account.pwd,
        reset: this.isReset,
      };
      if (this.dialogTitle == "提示") {
        if (!this.formOpenPc.command) {
          this.$message.error("请输入动态口令");
          return;
        }
        _api.updateRecyclerIsOpenPc(this.formOpenPc).then((res) => {
          this.$message.success(res.msg || "操作成功");
          this.assignAccDialogVisible = false;
        });
      }  else if (this.dialogTitle == "查看账号" && this.isReset&&this.acCopycount.acc==this.account.acc) {
        this.$confirm("此操作将重置门店商家密码, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            params.reset='01'
            _api.assignRecyclerStaffAcc(params).then((res) => {
              this.$message({
                type: "success",
                message: "重置成功!",
              });
              this.assignAccDialogVisible = false;
            });
          })
          .catch(() => { });
      }  else if (this.dialogTitle == "查看账号" && this.isReset&&this.acCopycount.acc!=this.account.acc) {
        this.$confirm("此操作将重置门店商家密码和账号, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            params.reset='03'
            _api.assignRecyclerStaffAcc(params).then((res) => {
              this.$message({
                type: "success",
                message: "重置成功!",
              });
              this.assignAccDialogVisible = false;
            });
          })
          .catch(() => { });
      } else if (this.dialogTitle == "查看账号" && !this.isReset&&this.acCopycount.acc!=this.account.acc) {
        this.$confirm("此操作将重置门店商家账号, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            params.reset='02'
            _api.assignRecyclerStaffAcc(params).then((res) => {
              this.$message({
                type: "success",
                message: "重置成功!",
              });
              this.assignAccDialogVisible = false;
            });
          })
          .catch(() => { });
      }
      else if (this.dialogTitle == "查看账号"&& !this.isReset&&this.acCopycount.acc==this.account.acc) {
        this.assignAccDialogVisible = false;
      } else {
        params.reset='00'
        _api.assignRecyclerStaffAcc(params).then((res) => {
          this.$message.success(res.msg || "操作成功");
          this.assignAccDialogVisible = false;
        });
      }
    },
    //虚拟报价
    VirtualSwitch(row) {
      this.contactCommand = "";
      this.contactMerchantId = row.id;
      this.isOpenMockQuote = row.isOpenMockQuote;
      this.VirtualVisible = true;
      this.isBatchprocess = false
      this.unitsNumber = 1
    },
    // 开启使用统一配置弹窗
    uniformSwitch(row) {
      this.contactCommand = "";
      this.contactMerchantId = row.id;
      this.isUniformly = row.isDefaultQuoteMachine;
      this.uniformVisible = true;
      this.isBatchprocess = false
      this.unitsNumber = 1
    },
    unityDialogClosed() {
      //取消
      this.uniformVisible = false;
      // 刷新列表
      if (this.contactMerchantId) {
        this.listCompany();
      }
    },
    // 是否可联系店员
    contactStaffSwitch(row) {
      this.contactCommand = "";
      this.contactMerchantId = row.id;
      if (row.isContact) {
        (this.dialogContactStaffTitle = "启用"),
          (this.contactStaffRedText =
            "说明：启用后，回收商创建人、子账号、报价师人员可查看店员联系电话");
      } else {
        (this.dialogContactStaffTitle = "关闭"),
          (this.contactStaffRedText =
            "说明：禁用后，回收商创建人、子账号、报价师人员均不可查看店员联系电话");
      }
      this.isContact = row.isContact;
      this.contactStaffVisible = true;
    },
    //开启或关闭虚拟报价配置
    VirtualSubmit() {
      if (!this.contactCommand) {
        return this.$message.error("请输入动态口令");
      }
      //确定
      let params = {
        "command": this.contactCommand,
        "companyIdList": [],
        "isOpenMockQuote": this.isOpenMockQuote,
        "merchantId": this.merchantIdType
      }
      //this.isBatchprocess 是否批量
      if (this.isBatchprocess) {
        params.companyIdList = this.unitySelectList.map(item => item.id)
      } else {
        params.isOpenMockQuote=this.isOpenMockQuote
        params.companyIdList.push(this.contactMerchantId)
      }
      _api.merchanmockQuoteBatch(params).then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.$message.success("操作成功");
          this.VirtualVisible = false;
          this.contactCommand = ""
          this.unitySelectList = []
          this.listCompany(1);
        } else {
          this.$message.error("操作失败");
        }
      });
    },
    //开启或关闭统一报价配置
    uniSubmit() {
      if (!this.contactCommand) {
        return this.$message.error("请输入动态口令");
      }
      //确定
      let params = {
        "command": this.contactCommand,
        "companyIds": [],
        "isDefaultQuoteMachine": true,
        "merchantId": this.merchantIdType
      }
      //this.isBatchprocess 是否批量
      if (this.isBatchprocess) {
        params.companyIds = this.unitySelectList.map(item => item.id)
      } else {
        params.isDefaultQuoteMachine=this.isUniformly
        params.companyIds.push(this.contactMerchantId)
      }
      _api.merchantDefaultQuoteBatch(params).then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.$message.success("操作成功");
          this.uniformVisible = false;
          this.contactCommand = ""
          this.unitySelectList = []
          this.listCompany(1);
        } else {
          this.$message.error("操作失败");
        }
      });
    },
    contactStaffhandleSuccess() {
      //确定
      let params = {
        merchantId: this.merchantIdType, //回收商id
        companyId: this.contactMerchantId, //	门店商id
        isContact: this.isContact,
        command: this.contactCommand,
      };
      _api.updateIsContact(params).then((res) => {
        // console.log(res);
        if (res.code === 1) {
          this.$message.success("操作成功");
          this.contactStaffVisible = false;
        } else {
          this.$message.error("操作失败");
        }
      });
    },
    contactStaffDialogClosed() {
      //取消
      this.contactStaffVisible = false;
      // 刷新列表
      this.listCompany();
    },
    contactStaffClosed() {
      //取消
      this.contactStaffVisible = false;
    },
    //合作商家 -- 正式
    listCompany(val) {
      if (val) {
        this.pageNum = val
      }
      const params = {
        companyName: this.companyName, //门店商家
        middleStatus: this.cooperationState, //合作状态
        receiptType: this.Receiver, //收货方
        merchantId: this.merchantIdType,
        isMatrixCompany: this.isMatrixCompany,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      _api.listCompany(params).then((res) => {
        if (res.code === 1) {
          this.midellData = res.data
          if (res.data.ipage) {
            this.DialogDataList = res.data.ipage.records.map((v) => {
              switch (v.receiveParty) {
                case "01":
                  v.receiveParty = "回收商";
                  break;
                case "02":
                  v.receiveParty = "平台";
                  break;
                default:
                  v.receiveParty = "--";
              }
              return v;
            });
          }
          this.total = res.data.ipage ? res.data.ipage.total : 0;
          this.middleIngNum = res.data.middleIngNum;
        }
        this.dialogLoading = false;
      });
    },
    //合作商家 --外部
    searchOperateList(val) {
      this.operateLoading = true;
      const params = {
        merchantId: this.currentRow.merchantId,
        pageNum: val || this.operateParams.pageNum,
        pageSize: this.operateParams.pageSize,
      };
      _api.findInquiryOutMerchant(params).then((res) => {
        if (res.code == 1) {
          this.dialogOperateList = res.data.records;
          this.operateParams.total = res.data.total;
          this.operateParams.pageNum = res.data.current;
          this.operateParams.pageSize = res.data.pageSize;
          this.operateLoading = false;
        }
      });
    },
    //员工数量
    listStaff() {
      const params = {
        merchantId: this.merchantIdType,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      _api.listStaff(params).then((res) => {
        if (res.code === 1) {
          this.DialogDataList = res.data.records;
          this.total = res.data.total;
        }
        this.dialogLoading = false;
      });
    },
    //累计成交订单
    recycleMerchantOrderList() {
      const params = {
        merchantId: this.merchantIdType,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      _api.recycleMerchantOrderList(params).then((res) => {
        if (res.code === 1) {
          this.DialogDataList = res.data.records;
          this.total = res.data.total;
        }
        this.dialogLoading = false;
      });
    },
    // 账户明细--充值明细
    payList() {
      const params = {
        merchantId: this.merchantIdType,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        staffId: this.detailedSearch.staffId,
        startTime: this.detailedSearch.time ? this.detailedSearch.time[0] : "",
        endTime: this.detailedSearch.time ? this.detailedSearch.time[1] : "",
      };
      _api.getPayList(params).then((res) => {
        if (res.code === 1) {
          this.DialogDataList = res.data.records;
          this.total = res.data.total;
        }
        this.dialogLoading = false;
      });
    },
    // 账户明细--使用明细
    rechargeList(type) {
      const params = {
        merchantId: this.merchantIdType,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        staffId: this.detailedSearch.staffId,
        companyId: this.detailedSearch.companyId,
        type,
        // 新增imei
        imei: this.detailedSearch.imei,
        startTime: this.detailedSearch.time ? this.detailedSearch.time[0] : "",
        endTime: this.detailedSearch.time ? this.detailedSearch.time[1] : "",
      };
      _api.getMerchantUsedLog(params).then((res) => {
        if (res.code === 1) {
          this.DialogDataList = res.data.records;
          this.total = res.data.total;
        }
        this.dialogLoading = false;
      });
    },
    //撮合扣款明细
    matchOrderDetail() {
      const params = {
        merchantId: this.merchantIdType,
        matchChannelId: this.detailedSearch.matchChannelId,
        companyId: this.detailedSearch.companyId,
        startTime: this.detailedSearch.time ? this.detailedSearch.time[0] : "",
        endTime: this.detailedSearch.time ? this.detailedSearch.time[1] : "",
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      _api.matchOrderDetail(params).then((res) => {
        if (res.code === 1) {
          this.DialogDataList = res.data.records;
          this.total = res.data.total;
        }
        this.dialogLoading = false;
      });
    },
    //合作回收商
    recycleChantList() {
      const params = {
        companyId: this.companyIdType,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      _api.recycleChantList(params).then((res) => {
        if (res.code === 1) {
          this.DialogDataList = res.data.records;
          this.total = res.data.total;
        }
        this.dialogLoading = false;
      });
    },
    cleanWechatInfo(merchantStaffId) {
      confirm({
        title: '操作提示',
        desc: '说明：该操作将清除登录过该账号微信信息，如微信头像、昵称、openID等，清除后重新登录该账号的微信将被再次关联。',
        message: '是否确认清除该账号关联的微信信息？',
        confirm: () => {
          _api.cleanWechatInfo({merchantStaffId }).then(() => {
            this.handleCurrentChangeDialog(1)
          })
        }
      })
    },
    // 外部
    DialoghandleOutBtn(row) {
      this.currentRow.merchantId = row.merchantId
      this.operateTitle = `合作商家-${row.merchantName}`
      this.operateVisible = true
      this.searchOperateList()
    },
     // 查看订单
     problemClick(row) {
      const { href } = this.$router.resolve({
        path: "/RecallOrder/index",
        query: {
          merchantId: row.merchantId,
        },
      });
      window.open(href, "_blank");
    },
    //弹窗
    DialoghandleBtn(row, type) {
      this.currentRow = row;
      this.pageNum = 1;
      this.slotNameHZ = false;
      this.DialogDataList = [];
      this.BtnType = type;
      this.merchantIdType = row.merchantId;
      this.bmerchantName = row.merchantName;
      this.isNiceMerchant = row.isNiceMerchant; //是否靓机商家
      this.companyIdType = row.id;
      this.deleteVisible = true;
      console.log(row, type);
      //合作商家
      this.dialogLoading = true;
      if (type === "business") {
        // 清空筛选
        this.companyName = '', //门店商家
        // this.cooperationState = '', //合作状态
        this.Receiver = '', //收货方
        // *********
        this.slotNameHZ = true;
        this.isMatrixCompany = ''
        this.hezuohui = false;
        this.title = `合作商家-${row.merchantName}`;
        this.DialogTableColumns = [
          { label: "商家名称", prop: "companyName" },
          { label: "联系人", prop: "contact" },
          { label: "联系电话", prop: "phone" },
          { label: "门店数量", prop: "stores" },
          { label: "员工数量", prop: "staffs" },
          { label: "商家地址", prop: "address" },
          { label: "累计成交订单", prop: "recycles" },
          { slotName: "operation" },
          { slotName: "channelName" },
          { slotName: "matrixName" },
          { slotName: "merchantInMatrixState" },
          { slotName: "isValidAppleId" },
          { slotName: "uniformpirce" },
          { slotName: "isflaigstaff" },
          { slotName: "receiveParty" },
          { slotName: "Virtualpirce" },
          { slotName: "offerOperation" },
        ];
        this.listCompany();
      }
      //员工数量
      else if (type === "staffCount") {
        this.hezuohui = false;
        this.title = `员工列表-${row.merchantName}`;
        this.DialogTableColumns = [
          { slotName: "staffName" },
          { label: "手机号", prop: "staffPhone" },
          { label: "累计成交订单", prop: "recycles" },
          { label: "日均报价订单", prop: "averageOrder" },
          { label: "成交率(%)", prop: "dealRatio" },
          { label: "平均报价时长(秒)", prop: "averageTime" },
          { label: "最近登录时间", prop: "lastLoginTime" },
          { label: "最近报价时间", prop: "lastQuoteTime" },
          { slotName: "isEnable" }, //新增账号状态
          { slotName: "isSubscribe" },
          { slotName: "officialAccounts" },
          { slotName: "recoveryQuota" },
          { slotName: "staffCountOperation" },
        ];
        this.listStaff();
      }
      //累计成交订单
      else if (type === "orderCount") {
        this.hezuohui = false;
        this.title = `累计成交-${row.merchantName}`;
        this.DialogTableColumns = [
          { label: "订单编号", prop: "orderNo" },
          { label: "下单时间", prop: "orderTime" },
          { label: "询价商家", prop: "createCompanyName" },
          { label: "询价门店", prop: "createStoreName" },
          { label: "报价金额", prop: "manualQuotePrice" },
          { label: "实际成交金额", prop: "manualFinalPrice" },
          { slotName: "state" },
          // { slotName: "operation" },
        ];
        this.recycleMerchantOrderList();
      }
      //收货次数
      else if (type === "remainScanTimes") {
        this.hezuohui = false;
        this.detailedTab = "充值明细";
        this.detailedType = "01";
        this.staffType = "购买人";
        (this.detailedSearch = {
          staffId: "",
          time: "",
          imei: "",
        }),
          (this.title = `查看明细-${row.merchantName}`);
        this.DialogTableColumns = [
          { label: "订单编号", prop: "orderNo" },
          { label: "支付时间", prop: "payTime" },
          { label: "充值金额", prop: "price" },
          { label: "数量", prop: "num" },
          { label: "到账次数", prop: "arrivalTimes" },
          { label: "赠送次数", prop: "giftTimes" },
          { label: "购买人", prop: "createStaffName" },
          { label: "购买人账号", prop: "createStaffMobile" },
        ];
        this.getStaffList("01");
        this.detailedShow = true;
        this.payList();
      }
      //合作回收商
      else if (type === "Number") {
        this.hezuohui = true;
        this.title = `合作回收商-${row.companyName}`;
        this.DialogTableColumns = [
          { label: "商家名称", prop: "merchantName" },
          { label: "联系人", prop: "contact" },
          { label: "联系电话", prop: "phone" },
          { label: "商家地址", prop: "merchantAddress" },
          { label: "入驻时间", prop: "createTime" },
          { slotName: "isflaigstaff" },
        ];
        this.recycleChantList();
      }
    },
    //启用弹窗取消
    resetPopupData() {
      this.dialogVisible = false;
    },
    //启用弹窗确定
    submitPopupData() {
      if (this.EnableType === "success") {
        let _this = this;

        if (!this.formOpenPccommand) {
          _this.$message({
            message: "请输入动态口令!",
            type: "warning",
          });
          return;
        }

        const params = {
          command: this.formOpenPccommand,
          disableNote: this.disableNote,
          merchantId: this.ShowmerchantId,
          type: "01",
          isEnable: true,
        };

        _api.modifyMerchantListOper(params).then((res) => {
          if (res.code === 1) {
            this.dialogVisible = false;
            this.$message.success(res.msg);
          } else {
            this.$message.danger(res.msg);
          }
        });
      } else if (this.EnableType === "danger") {
        let _this = this;
        if (!this.disableNote) {
          _this.$message({
            message: "请输入禁用原因!",
            type: "warning",
          });
          return;
        }
        if (!this.formOpenPccommand) {
          _this.$message({
            message: "请输入动态口令!",
            type: "warning",
          });
          return;
        }
        const params = {
          command: this.formOpenPccommand,
          disableNote: this.disableNote,
          merchantId: this.ShowmerchantId,
          type: "01",
          isEnable: false,
        };
        _api.modifyMerchantListOper(params).then((res) => {
          if (res.code === 1) {
            this.dialogVisible = false;
            this.$message.success(res.msg);
          } else {
            this.$message.danger(res.msg);
          }
        });
      }
      this.handleCurrentChange();
    },
    onClose() {
      this.deleteVisible = false;
      this.dialogVisible = false;
      this.detailedShow = false;
    },
    // 购买人（使用人）列表
    getStaffList(type) {
      const params = {
        merchantId: this.merchantIdType,
        type,
      };
      _api.recycleStaffSelect(params).then((res) => {
        if (res.code === 1) {
          this.staffList = res.data;
        }
      });
    },
    // 购买人（使用人）列表
    getCompany() {
      const params = {
        merchantId: this.merchantIdType,
      };
      _api.listCompany(params).then((res) => {
        if (res.code === 1) {
          console.log(res);
          this.midellData = res.data
          this.staffList = res.data.records;
        }
        this.dialogLoading = false;
      });
    },
    // 使用明细和充值明细切换
    detailedChange(e) {
      this.detailedSearch = {
        staffId: "",
        time: [],
        imei: "",
      };
      this.pageNum = 1;
      this.dialogLoading = true;
      if (e == "充值明细") {
        this.DialogTableColumns = [
          { label: "订单编号", prop: "orderNo" },
          { label: "支付时间", prop: "payTime" },
          { label: "充值金额", prop: "price" },
          { label: "数量", prop: "num" },
          { label: "到账次数", prop: "arrivalTimes" },
          { label: "赠送次数", prop: "giftTimes" },
          { label: "购买人", prop: "createStaffName" },
          { label: "购买人账号", prop: "createStaffMobile" },
        ];
        this.staffType = "购买人";
        this.getStaffList("01");
        this.payList();
      } else if (e == "收货使用明细") {
        this.DialogTableColumns = [
          { label: "订单编号", prop: "recycleOrderNo" },
          { label: "收货时间", prop: "createTime" },
          { label: "收货人姓名", prop: "usedStaffName" },
          { label: "收货人账号", prop: "usedStaffPhone" },
          { label: "扣减次数", prop: "nowTimes" },
        ];
        this.staffType = "收货人";
        this.getStaffList("02");
        this.rechargeList("03");
      } else if (e == "苹果锁使用明细") {
        this.DialogTableColumns = [
          { label: "订单编号", prop: "recycleOrderNo" },
          { label: "门店商家", prop: "companyName" },
          { slotName: "companyStaffName" },
          { label: "旧机名称", prop: "phoneName" },
          { label: "IMEI号", prop: "imei" },
          { slotName: "imeiEffective" },
          { label: "验证时间", prop: "createTime" },
          { label: "扣减次数", prop: "nowTimes" },
        ];
        this.staffType = "门店商家";
        this.getCompany();
        this.rechargeList("04");
      } else if (e == "绑码使用明细") {
        this.DialogTableColumns = [
          { label: "绑码订单编号", prop: "recycleOrderNo" },
          { label: "绑码时间", prop: "createTime" },
          { slotName: "bindingStaff" },
          { label: "扣减次数", prop: "nowTimes" },
        ];
        this.staffType = "绑定";
        this.rechargeList("07");
      } else if (e == "退回明细") {
        this.DialogTableColumns = [
          { label: "退回订单编号", prop: "recycleOrderNo" },
          { label: "退回时间", prop: "createTime" },
          { slotName: "returnStaff" },
          { label: "退回次数", prop: "nowTimes" },
          { label: "类型", prop: "title" },
        ];
        this.staffType = "绑定";
        this.rechargeList("08");
      } else if (e == "撮合扣款明细") {
        this.DialogTableColumns = [
          { label: "订单编号", prop: "orderNo" },
          { slotName: "opt" },
          { label: "账单时间", prop: "createTime" },
          { label: "撮合渠道", prop: "matchChannelName" },
          { label: "成交门店商户", prop: "companyName" },
          { slotName: "Tax" },
        ];
        this.staffType = "撮合渠道";
        this.getMiddleAdoptCompany();
        this.matchOrderDetail();
      } else if (e == "串号查询使用明细") {
        //新增***
        this.DialogTableColumns = [
          { label: "查询串号", prop: "imei" },
          { label: "查询接口", prop: "title" },
          { slotName: "usedStaff" }, //查询人
          { label: "查询时间", prop: "createTime" },
          { label: "扣减次数", prop: "nowTimes" },
        ];
        this.staffType = "查询人";
        this.getStaffList("02");
        this.rechargeList("09");
      } else if (e == "下账明细") {
        this.DialogTableColumns = [
          { label: "下账单号", prop: "minusNo" },
          { label: "下账时间", prop: "minusTime" },
          { label: "下账次数", prop: "nowTimes" },
          { label: "下账原因", prop: "minusCause" },
        ];
        this.staffType = "绑定";
        this.rechargeList("10");
        console.log('下账la');
      }
    },
    searchSetting() {
      this.pageNum = 1;
      this.dialogLoading = true;
      if (this.detailedTab == "充值明细") {
        this.payList();
      } else if (this.detailedTab == "收货使用明细") {
        this.rechargeList("03");
      } else if (this.detailedTab == "苹果锁使用明细") {
        this.rechargeList("04");
      } else if (this.detailedTab == "绑码使用明细") {
        this.rechargeList("07");
      } else if (this.detailedTab == "退回明细") {
        this.rechargeList("08");
      } else if (this.detailedTab == "撮合扣款明细") {
        this.matchOrderDetail();
      } else if (this.detailedTab == "串号查询使用明细") {
        this.rechargeList("09");
      } else if (this.detailedTab == "下账明细") {
        this.rechargeList("10");
      }
      console.log(this.detailedSearch, "343434");
    },
    // 获取渠道
    getMatchChannels() {
      _api.getMatchChannelSelect().then((res) => {
        if (res.code === 1) {
          this.channelSelectList = res.data;
        }
      });
    },
    // 已合作门店商家下拉列表
    getMiddleAdoptCompany() {
      _api.matchCompanyList({ merchantId: this.merchantIdType }).then((res) => {
        if (res.code === 1) {
          this.alreadyCompanyList = res.data;
        }
      });
    },
    BtnClick(type,numty) {
      this.detailedType =numty
      if (type == "active" && this.detailedType == "01") {
        this.detailedTab = "充值明细";
        this.detailedChange("充值明细");
      } 
      if (type == "before" && this.detailedType == "02") {
        this.detailedTab = "收货使用明细";
        this.detailedChange("收货使用明细");
      } 
    },
    seeLogisticsSet(row) {
      this.getLogisticsConfigInfo(row.merchantId);
      this.setDialogVisible = true;
    },
    setDialogClose() {
      this.setDialogVisible = false;
    },
    //获取物流规则
    getLogisticsConfigInfo(merchantId) {
      _api.getLogisticsConfigInfo({ merchantId }).then((res) => {
        if (res.code === 1) {
          this.rulesInfo = res.data;
          this.expressList = res.data.expressList;
        }
      });
    },
    // 关闭/开启报价
    offerOperation(row) {
      this.relationRow = row;
      if (row.companyIsEnable == "01") {
        _api.closeQuoteQueryMaxQuoteNum({
          "companyIds": [row.id],
          "merchantId": this.merchantIdType
        }).then(res => {
          this.closePriceNumber = res.data
        })
      }
      this.offerDialogShow = true;
    },
    // 报价操作弹框关闭
    offerDialogClose() {
      this.command = "";
      this.relationRow = {};
      this.procedures = "";
    },
    baoDialogClose(){
      this.command=''
      this.handleCurrentChange();
    },
     //回收额度配置
     isAccountLimitDialogSure() {
      let params = {
        isAccountLimit: this.HealthCodeCusdata.isAccountLimit,
        merchantId: this.HealthCodeCusdata.merchantId,
      };
      this.offerDialogBtnLoading = true;
        _api
          .updateaccountLimit(params)
          .then((res) => {
            if (res.code == 1) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.handleCurrentChange();
              this.AccountLimitDialogShow = false;
            }
            this.offerDialogBtnLoading = false;
          })
          .catch(() => {
            this.offerDialogBtnLoading = false;
          });
    },
    ERPtimatedDialogSure(){
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return false;
      }
      let params = {
        command: this.command,
        isSyncErp: this.HealthCodeCusdata.isSyncErp,
        merchantId: this.HealthCodeCusdata.merchantId,
      };
        _api
          .updatesysERP(params)
          .then((res) => {
            if (res.code == 1) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.command=''
              this.erpDialogShow = false;
            }
          })
    },
     //报价填写预估机操作弹框确认按钮
     estimatedDialogSure() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return false;
      }
      let params = {
        command: this.command,
        isHealthCodeCus: this.HealthCodeCusdata.isHealthCodeCus,
        id: this.HealthCodeCusdata.merchantId,
      };
      this.offerDialogBtnLoading = true;
        _api
          .updateIsHealthCodeCus(params)
          .then((res) => {
            if (res.code == 1) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.handleCurrentChange();
              this.estimatedDialogShow = false;
            }
            this.offerDialogBtnLoading = false;
          })
          .catch(() => {
            this.offerDialogBtnLoading = false;
          });
    },
    // 报价操作弹框确认按钮
    offerDialogSure() {
      if (
        this.relationRow.payApplyStatus == "02" &&
        this.relationRow.companyIsEnable == "02" &&
        !this.procedures
      ) {
        this.$message.error("请选择手续费承担方");
        return false;
      }
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return false;
      }
      let params = {
        command: this.command,
        companyId: this.relationRow.id,
        merchantId: this.currentRow.merchantId,
      };
      this.offerDialogBtnLoading = true;
      if (this.relationRow.companyIsEnable == "01") {
        _api
          .closeQuote(params)
          .then((res) => {
            if (res.code == 1) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.offerDialogShow = false;
              this.listCompany();
            }
            this.offerDialogBtnLoading = false;
          })
          .catch(() => {
            this.offerDialogBtnLoading = false;
          });
      } else {
        params.processFeeBear = this.procedures;
        _api
          .saveUnionMerchant(params)
          .then((res) => {
            if (res.code == 1) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.offerDialogShow = false;
              this.listCompany();
            }
            this.offerDialogBtnLoading = false;
          })
          .catch(() => {
            this.offerDialogBtnLoading = false;
          });
      }
    },
    optionsFilter(v, options) {
      return options.find(item => item.value == v)?.label
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .fzs16 {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    text-align: center;
  }

  .acc_dialog {
    /deep/ .el-input {
      width: 240px;
    }
  }

  .texleft {
    text-align: left;
  }

  .mb20 {
    margin-bottom: 30px;
  }

  .mt20 {
    margin-top: 20px;
  }

  .mb30 {
    margin-bottom: 30px;
  }

  .tableBtn {
    color: #0981ff;
    cursor: pointer;
  }

  .handleClass {
    display: inline-block;
    margin-left: 5px;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    justify-content: space-between;
  }
}

.command {
  display: flex;
  align-items: center;
}

.command .el-input {
  width: 80%;
}

// 备注说明
.Remarks {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; //控制行数
  overflow: hidden;
}

.button_top {
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.faultBtn {
  width: 200px;
  height: 42px;
  background: #f9fbfd;
  border: 1px solid #c1d1ff;
  box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
  border-radius: 21px;
  display: flex;

  .left {
    text-align: center;
    color: white;
    width: 50%;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 40px;
    height: 40px;
    background: #0981ff;
    border-radius: 20px;
  }

  .right {
    text-align: center;
    color: #333;
    width: 50%;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 40px;
    height: 40px;
    border-radius: 20px;
  }
}

.disltBtn {
  height: 34px;
  background: #f9fbfd;
  border: 1px solid #c1d1ff;
  box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
  border-radius: 21px;
  display: flex;
  cursor: pointer;

  .none {
    text-align: center;
    color: #333;
    padding: 0 15px;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 32px;
    height: 32px;
    border-radius: 20px;
  }

  .active {
    color: white;
    background: #0981ff;
  }
}

.channeltype1 {
  height: 16px;
  background: #8080ff;
  border-radius: 4px;
  padding: 2px;
  line-height: 20px;
  font-size: 12px;
  color: #ffffff;
  margin-right: 6px;
}

.channeltype2 {
  height: 16px;
  background: #fba516;
  border-radius: 4px;
  padding: 2px;
  line-height: 20px;
  font-size: 12px;
  color: #ffffff;
  margin-right: 6px;
}

.verify-title {
  color: #0981ff;
  line-height: 16px;
  font-size: 16px;
  padding-left: 10px;
  border-left: 3px solid;
  margin-bottom: 10px;
}

.enable-tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;

  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
  }

  .set_top_ck {
    span {
      font-size: 15px;
      font-weight: bold;
      margin-right: 10px;
    }
  }
}

.categorySet {
  min-height: 82px;
  background: #ffffff;
  border: 1px solid #e4ecfd;
  border-radius: 10px;
  margin: 20px 0;

  .cs_center {
    flex-wrap: wrap;
    padding: 0 25px;
    min-height: 34px;
    background: #eef3fe;
    border: 1px solid #e4ecfd;
    border-radius: 10px 10px 0px 0px;
  }
}

.andleShowbox {
  border: 1px solid #0981ff;
  width: 200px;
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  border-radius: 15px;

  .andleShowstyle {
    border-radius: 15px;
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #0981ff;
    cursor: pointer;
    user-select: none;
  }

  .andleShowcolor {
    background-color: #0981ff;
    color: #fff;
  }
}

.dis_flex {
  display: flex;
}

.al_c {
  align-items: center;
}

.ju_sb {
  justify-content: space-between;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-50 {
  margin-right: 50px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.font-w {
  font-weight: bold;
}

.flex-as {
  display: flex;
  align-items: flex-start;
}

.service_charge {
  /deep/ .el-switch__label * {
    line-height: 1;
    font-size: 12px;
    display: inline-block;
  }

  /deep/ .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
    font-size: 12px !important;
  }

  /*打开时文字位置设置*/
  /deep/ .el-switch__label--right {
    z-index: 1;
    right: 4px;
  }

  /*关闭时文字位置设置*/
  /deep/ .el-switch__label--left {
    z-index: 1;
    left: 4px;
  }

  /*显示文字*/
  /deep/ .el-switch__label.is-active {
    display: block;
  }

  /*开关宽度*/
  /deep/ .el-switch .el-switch__core,
  /deep/ .el-switch .el-switch__label {
    width: 50px !important;
  }
}

/deep/.el-checkbox {
  margin-bottom: 10px;
}

.selection_type {
  display: flex;
  align-items: center;
  margin-right: 30px;
  cursor: not-allowed;

  img {
    margin-right: 10px;
    width: 14px;
    height: 14px;
  }
}

.clamp2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.centercontent {
  font-size: 16px;
  color: #666666;
  text-align: center;
  height: 80px;
  line-height: 80px;
}

.step {
  .step-title {
    display: flex;
    color: #ff687b;
    margin-bottom: 18px;
  }

  .fileName {
    /deep/.el-input {
      width: 260px;
    }

    /deep/.el-button {
      // border-radius: 10px;
      margin-right: 0px;
    }
  }

  .step-content {
    margin-bottom: 20px;
  }
}
</style>
