<template>
  <div class="search">
    <span style="display: flex; align-items: center">
      <span style="width: 100px">品牌名称：</span>
      <el-input v-model="searchForm.brandName" clearable placeholder="请输入品牌名称"></el-input>
    </span>
    <span style="display: flex; align-items: center; margin-left: 20px">
      <span style="width: 100px">机型名称：</span>
      <el-input v-model="searchForm.name" clearable placeholder="请输入机型名称"></el-input>
    </span>
    <span style="display: flex; align-items: center; margin-left: 20px" v-if="Autoquotationtype==3">
      <span style="width: 100px">机型来源：</span>
      <el-select v-model="searchForm.channel" clearable placeholder="请选择">
          <el-option label="采货侠" value="采货侠" />
          <el-option label="分毫" value="分毫" />
          <el-option label="荣耀" value="荣耀" />
          <el-option label="嗨回收" value="嗨回收" />
      </el-select>
    </span>
    <el-button type="primary" style="margin-left: 10px" @click="searchPhoneModel">查询</el-button>
  </div>
</template>

<script>
export default {
  props: {
    searchData: {
      type: Object
    },
    Autoquotationtype:{
      type:Number,
      default:2
    }
  },
  data () {
    return {
      searchForm: {
        brandName: '',
        name: '',
        channel:"",
      }
    }
  },
   methods: {
      searchPhoneModel () {
        this.$emit('searchForm', this.searchForm)
      }
   },
   watch: {
    searchData (val) {
      this.searchForm = val
    }
   },
}
</script>

<style>
.search {
  display: flex;
}
</style>