<template>
  <div>
    <el-dialog
      title="回收商列表"
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="1100px"
    >
      <el-form ref="filterForm" :model="filterForm" inline size="small" class="el-form-group_isRound">
        <el-form-item label="回收商" prop="merchantId">
          <el-select v-model="filterForm.merchantId" clearable filterable placeholder="请输入回收商名称查询" >
            <el-option v-for="item in merchantList" :key="item.merchantId" :label="item.merchantName" :value="item.merchantId" />
          </el-select>
        </el-form-item>
        <el-form-item label="是否开启限时报价" prop="isLimitQuote">
          <el-select v-model="filterForm.isLimitQuote" clearable>
            <el-option v-for="item in isLimitQuoteOptions" :key="item.label" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" round @click="getData()">查询</el-button>
        </el-form-item>
        <el-button type="danger" size="small" style="float: right" @click="openSetting('batch')">批量设置限时报价</el-button>
      </el-form>
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        isSelection
        isIndex
        :maxHeight="500"
        :columns="columns"
        :data="list"
        :currentPage="filterForm.pageNum"
        :pageSize="filterForm.pageSize"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @handleCurrentChange="getData"
        @handleSelectionChange="selectionChange"
        @handleSizeChange="handleSizeChange"
      >
        <el-table-column label="是否开启限时报价" slot="isLimitQuote" align="center">
          <template slot-scope="{ row }">
            {{ row.isLimitQuote | optionsFilter(isLimitQuoteOptions) }}
          </template>
        </el-table-column>
        <el-table-column label="最近修改人" slot="updateAdminName" align="center">
          <template slot-scope="{ row }">
            {{ row.updateAdminName }}-{{ row.updateAdminAcc }}
          </template>
        </el-table-column>
        <el-table-column label="最近修改时间" slot="updateTime" align="center">
          <template slot-scope="{ row }">
            {{ row.updateTime || '-' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="operation" align="center">
          <template slot-scope="{ row }">
            <el-button type="danger" size="small" @click="openSetting('one', row)">限时报价设置</el-button>
          </template>
        </el-table-column>
        
      </GlobalTable>
    </el-dialog>
    <TimeQuoteSettingDia ref="timeQuoteSettingDia" @confirm="getData()" />
  </div>
</template>

<script>
import _api from "@/utils/request";
import TimeQuoteSettingDia from './TimeQuoteSettingDia.vue';
export default {
  components: { TimeQuoteSettingDia },
  name: "TimeQuoteMerchantDia",
  filters: {
    optionsFilter(v, options) {
      return options.find(item => item.value == v)?.label
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      filterForm: {
        pageNum: 1,
        pageSize: 10,
        merchantId: '',
        isLimitQuote: ''
      },
      total: 0,
      list: [],
      columns: [
        { label: "回收商名称", prop: "merchantName" },
        { label: "联系人", prop: "contactName" },
        { label: "联系电话", prop: "contactPhone" },
        { label: "合作中商户", prop: "businessPartnerCount" },
        { slotName: "isLimitQuote" },
        { slotName: "updateAdminName" },
        { slotName: "updateTime" },
        { slotName: "operation" },
      ],
      isLimitQuoteOptions: [
        { label: '是', value: true },
        { label: '否', value: false },
      ],
      merchantList: [],
      selectList: []
    };
  },
  methods: {
    open(row) {
      this.row = row
      this.$refs['filterForm']?.resetFields()
      this.visible = true
      this.getData()
      this.getMerchantList()
    },
    getData(pageNum = 1) {
      this.loading = true
      _api.limitExclusiveConfigMerchantList({
        ...this.filterForm,
        pageNum,
      }).then(res => {
        this.list = res.data.records
        this.filterForm.pageNum = res.data.current
        this.total = res.data.total
      }).finally(() => {
        this.loading = false
      })
    },
    openSetting(type, row) {
      if (type === 'batch') {
        if (!this.selectList.length) return this.$message.error('请选择商家');
        this.$refs['timeQuoteSettingDia'].open(type, this.selectList);
        return
      } else {
        this.$refs['timeQuoteSettingDia'].open(type, row)
      }
    },
    getMerchantList() {
      _api.getMerchantSelectList().then(res => {
        this.merchantList = res.data
      })
    },
    handleSizeChange(num) {
      this.filterForm.pageSize = num
      this.getData()
    },
    selectionChange(v) {
      this.selectList = v.map(item => item.merchantId)
    }
  }
};
</script>