<template>
  <el-dialog :title="isWechatPush ? '停用提示' : '启用提示'" :visible.sync="visible" :close-on-click-modal="false" width="480px">
    <p v-if="isWechatPush" style="color: #ff8080;margin-bottom: 25px;">说明：手动停用后，系统将不会自动启用推送，如需开启需手动操作</p>
    <p v-else style="color: #ff8080;margin-bottom: 25px;">说明：手动启用后，若该账号超过48小时未报价，系统将再次停用推送</p>
    <div style="text-align: center; margin-bottom: 20px">
      是否确认{{ isWechatPush ? '停用' : '启用'}}该账号的消息推送？
    </div>
    <el-form ref="formRef" :model="formData" :rules="formRules">
      <el-form-item label="操作原因：" prop="cause">
        <el-input v-model="formData.cause" type="textarea" maxlength="50" :rows="5" :placeholder="'请输入'+(isWechatPush ? '停用' : '启用')+'原因，最多50字'" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import _api from "@/utils/request";
export default {
  data() {
    return {
      visible: false,
      isWechatPush: false,
      row: null,
      formData: {
        cause: ''
      },
      formRules: {
        cause: [{ required: true, message: '请输入原因' , trigger: "change" }]
      },
    }
  },
  methods: {
    open(row) {
      const { isWechatPush } = row
      this.isWechatPush = isWechatPush
      this.row = row
      this.formData = {
        cause: ''
      }
      this.$refs['formRef']?.resetFields()
      this.$nextTick(() => {
        this.visible = true
      })
    },
    confirm() {
      const { row, isWechatPush } = this
      this.$refs['formRef'].validate((vaild) => {
        if (vaild) {
          _api.openCloseWechatPush({
            ...this.formData,
            isWechatPush: !isWechatPush,
            merchantId: row.merchantId,
            merchantStaffId: row.staffId
          }).then(() => {
            this.visible = false
            this.$emit('confirm')
          })
        }
      })
    }
  }
}
</script>