<template>
  <div>
    <el-dialog
      title="回收商列表"
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="1100px"
    >
      <el-form
        ref="filterForm"
        :model="filterForm"
        inline
        size="small"
        class="el-form-group_isRound"
      >
        <el-form-item label="回收商" prop="merchantId">
          <el-select
            v-model="filterForm.merchantId"
            clearable
            filterable
            multiple
            placeholder="请输入回收商名称查询"
          >
            <el-option
              v-for="item in merchantList"
              :key="item.merchantId"
              :label="item.merchantName"
              :value="item.merchantId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="商户简称" prop="shortName">
          <el-input
           clearable
            placeholder="请输入商户简称查询"
            v-model="filterForm.shortName"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" round @click="getData()">查询</el-button>
        </el-form-item>
        <el-button
          type="danger"
          size="small"
          :disabled="selectList.length === 0"
          style="float: right"
          v-if="cooperationState == '01'"
          @click="openSetting('batch')"
          >批量关闭</el-button
        >
        <el-button
          v-else
          type="success"
          size="small"
          :disabled="selectList.length === 0"
          style="float: right"
          @click="openSetting('batch')"
          >批量开启</el-button
        >
      </el-form>
      <div class="disltBtn">
        <div
          :class="{ none: true, active: cooperationState == '01' }"
          @click="statusClick('01')"
        >
          启用中
        </div>
        <div
          :class="{ none: true, active: cooperationState == '02' }"
          @click="statusClick('02')"
        >
          已关闭
        </div>
      </div>
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        isSelection
        isIndex
        :maxHeight="500"
        :columns="columns"
        :data="list"
        :currentPage="filterForm.pageNum"
        :pageSize="filterForm.pageSize"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @handleCurrentChange="getData"
        @handleSelectionChange="selectionChange"
        @handleSizeChange="handleSizeChange"
      >
        <el-table-column label="回收商简称" slot="shortName" align="center">
          <template slot-scope="{ row }">
            {{ row.shortName || "--" }}
          </template>
        </el-table-column>
        <el-table-column
          label="最近操作人"
          slot="updateAdminName"
          align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="!row.operator">--</span>
             <span v-else>{{ row.operator.adminName }}-{{ row.operator.adminAcc }}</span>
          </template>
        </el-table-column>
        <el-table-column label="最近操作时间" slot="updateTime" align="center">
          <template slot-scope="{ row }">
            <span v-if="!row.operator">--</span>
             <span v-else>{{ row.operator.updateTime||"--" }}</span>
          </template>
        </el-table-column>
      </GlobalTable>
    </el-dialog>
    <!--  操作提示-->
    <el-dialog
      title="操作提示"
      :visible.sync="turnshow"
      :close-on-click-modal="false"
      width="500px"
      @closed="timeLimitCommand = ''"
    >
      <div
        style="color: #ff8080; margin-bottom: 20px"
        v-if="cooperationState == '01'"
      >
        说明：禁用后，商家统计页面不显示本月、上月、选择时间查询条件
      </div>
      <div style="color: #ff8080; margin-bottom: 20px" v-else>
        说明：启用后，商家统计页面显示本月、上月、选择时间查询条件
      </div>
      <div class="tip-sure">
        是否确认{{ cooperationState == "02" ? "开启" : "关闭" }}
        <span style="color: #0981ff">{{ selectList.length }}个商家</span
        >在小程序-统计支持查询31天功能？
      </div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input
          v-model="timeLimitCommand"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click.native="
            turnshow = false;
            timeLimitCommand = '';
          "
          >取消</el-button
        >
        <el-button type="primary" @click.native="timeLimitCommandSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import _api from "@/utils/request";
export default {
  name: "TimeQuoteMerchantDia",
  filters: {
    optionsFilter(v, options) {
      return options.find((item) => item.value == v)?.label;
    },
  },
  data() {
    return {
      timeLimitCommand: "",
      turnshow: false,
      cooperationState: "01",
      visible: false,
      loading: false,
      filterForm: {
        pageNum: 1,
        pageSize: 10,
        shortName: "",
        merchantId: [],
        isLargeScaleQuery: true,
      },
      total: 0,
      list: [],
      columns: [
        { label: "回收商名称", prop: "merchantName" },
        { slotName: "shortName" },
        { label: "联系人", prop: "contactName" },
        { label: "联系电话", prop: "contactPhone" },
        { slotName: "updateAdminName" },
        { slotName: "updateTime" },
      ],
      isLimitQuoteOptions: [
        { label: "是", value: true },
        { label: "否", value: false },
      ],
      merchantList: [],
      selectList: [],
    };
  },
  methods: {
    timeLimitCommandSubmit() {
      if (!this.timeLimitCommand) {
        return this.$message.error("请输入谷歌验证码");
      }
      _api
        .getscaleQueryAction({
          command: this.timeLimitCommand,
          isLargeScaleQuery: this.cooperationState == "01" ? false : true,
          merchantIdList: this.selectList,
        })
        .then((res) => {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.turnshow = false;
          this.getData();
        });
    },
    statusClick(type) {
      this.cooperationState = type;
      this.filterForm.isLargeScaleQuery = type == "01" ? true : false;
      this.getData();
    },
    open(row) {
      this.row = row;
      this.$refs["filterForm"]?.resetFields();
      this.visible = true;
      this.getData();
      this.getMerchantList();
    },
    getData(pageNum = 1) {
      this.loading = true;
      _api
        .getlargeScaleList({
          ...this.filterForm,
          pageNum,
        })
        .then((res) => {
          this.list = res.data.records;
          this.filterForm.pageNum = res.data.current;
          this.total = res.data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openSetting(type, row) {
      this.turnshow = true;
    },
    getMerchantList() {
      _api.getMerchantSelectList().then((res) => {
        this.merchantList = res.data;
      });
    },
    handleSizeChange(num) {
      this.filterForm.pageSize = num;
      this.getData();
    },
    selectionChange(v) {
      this.selectList = v.map((item) => item.merchantId);
    },
  },
};
</script>
<style scoped lang="scss" ref="stylesheet/scss">
.tip-sure {
  margin-top: 10px;
  color: #333333;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}
.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.disltBtn {
  height: 34px;
  margin-bottom: 20px;
  background: #f9fbfd;
  border: 1px solid #c1d1ff;
  box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
  border-radius: 21px;
  display: inline-flex;
  cursor: pointer;

  .none {
    text-align: center;
    color: #333;
    padding: 0 15px;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 32px;
    height: 32px;
    border-radius: 20px;
  }

  .active {
    color: white;
    background: #0981ff;
  }
}
</style>