<template>
  <div>
    <el-dialog
      title="添加从账户"
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="900px"
    >
      <p style="color: #ff8080;margin-bottom: 25px;">说明：添加为该商户从账户后，主从账户余额可互相调账</p>
      <el-card>
        <el-form ref="filterForm" :model="filterForm" inline size="small" class="el-form-group_isRound">
          <el-form-item label="回收商" prop="merchantId">
            <el-select v-model="filterForm.merchantId" clearable filterable placeholder="请输入回收商名称查询" >
              <el-option v-for="item in merchantList" :key="item.merchantId" :label="item.merchantName" :value="item.merchantId" />
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="账户类型" prop="accountType">
            <el-select v-model="filterForm.accountType" clearable>
              <el-option v-for="item in accountTypeOptions" :key="item.label" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" round @click="getData()">查询</el-button>
          </el-form-item>
        </el-form>
        <GlobalTable
          ref="GlobalTable"
          v-loading="loading"
          isSelection
          isIndex
          :maxHeight="500"
          :columns="columns"
          :data="list"
          :currentPage="filterForm.pageNum"
          :pageSize="filterForm.pageSize"
          :total="total"
          :isCheckbox="(row) => !row.isSlaveAccount"
          layout="total, sizes, prev, pager, next, jumper"
          @handleCurrentChange="getData"
          @handleSelectionChange="selectionChange"
          @handleSizeChange="handleSizeChange"
        >
          <el-table-column label="账户类型" slot="accountType" align="center" width="140px">
            <template slot-scope="{ row }">
              {{ row.accountType | optionsFilter(accountTypeOptions) }}({{ row.isSlaveAccount ? '已启用从账户' : '未启用从账户' }})
            </template>
          </el-table-column>
        </GlobalTable>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="visible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
    <CommandDia ref="commandDia" />
  </div>
</template>

<script>
import _api from "@/utils/request";
import CommandDia from './CommandDia.vue';
export default {
  components: { CommandDia },
  name: "AddSubAccountDia",
  filters: {
    optionsFilter(v, options) {
      return options.find(item => item.value == v)?.label
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      row: {},
      filterForm: {
        pageNum: 1,
        pageSize: 10,
        merchantId: '',
        accountType: ''
      },
      total: 0,
      list: [],
      columns: [
        { label: "商家名称", prop: "merchantName" },
        { label: "联系人", prop: "contactName" },
        { label: "联系电话", prop: "contactPhone" },
        { label: "当前账户余额", prop: "balance" },
        { slotName: "accountType" },
      ],
      accountTypeOptions: [
        { label: '主账户', value: '00' },
        { label: '从账户', value: '01' },
      ],
      merchantList: [],
      selectList: []
    };
  },
  methods: {
    open(row) {
      this.row = row
      this.$refs['filterForm']?.resetFields()
      this.visible = true
      this.getData()
      this.getMerchantList()
    },
    getData(pageNum = 1) {
      this.loading = true
      _api.slaveAddList({
        ...this.filterForm,
        pageNum,
      }).then(res => {
        this.list = res.data.records
        this.filterForm.pageNum = res.data.current
        this.total = res.data.total
      }).finally(() => {
        this.loading = false
      })
    },
    getMerchantList() {
      _api.getMerchantSelectList().then(res => {
        this.merchantList = res.data
      })
    },
    handleSizeChange(num) {
      this.filterForm.pageSize = num
      this.getData()
    },
    selectionChange(v) {
      this.selectList = v.map(item => item.merchantId)
    },
    confirm() {
      if (!this.selectList.length) return this.$message.error('请先选择商户！')
      this.$refs['commandDia'].open({
        title: '操作提醒',
        desc: '说明：设为主账户后，该商家原账户余额将自动调账到主账户',
        message: `是否确认将以下商户设置为<span style="color:#3a9aff">${this.row.merchantName}</span>的从账户？`,
        confirm: ({ command, close }) => {
          _api.addSlaveAccount({
            masterMerchantId: this.row.merchantId,
            slaveMerchantIdList: this.selectList,
            command: command
          }).then(() => {
            close()
            this.$message.success('操作成功！')
            this.visible = false
            this.$emit('confirm')
          })
        }
      })
    },
  }
};
</script>