<template>
  <el-dialog
    title="添加规则"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="680px"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="120px" label-position="left">
      <el-form-item label="时间段：" prop="limitExclusiveStart">
        <div style="display:flex">

          <el-form-item label-width="0" prop="limitExclusiveStart">
            <el-time-select
              v-model="formData.limitExclusiveStart"
              :picker-options="{
                start: '00:00',
                step: '00:05',
                end: '24:00'
              }"
              placeholder="起始时间">
            </el-time-select>
          </el-form-item>
          <div style="margin:0 10px">~</div>
          <el-form-item label-width="0" prop="limitExclusiveEnd">
            <el-time-select
              v-model="formData.limitExclusiveEnd"
              :picker-options="{
                start: formData.limitExclusiveStart,
                step: '00:05',
                end: '24:00'
              }"
              placeholder="结束时间">
            </el-time-select>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="限时抢单时长：" prop="limitExclusiveLength">
        <el-input v-model="formData.limitExclusiveLength" placeholder="请输入大于10的整数" style="width: 440px" /> 分钟
      </el-form-item>
    </el-form>
    
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "TimeQuoteRuleDia",
  props: {
    ruleList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const validateRange = (rule, value, callback) => {
      const { ruleList } = this
      let findItem = null
      // 开始包含
      if (rule.field === 'limitExclusiveStart') {
        findItem = ruleList.find(item => item.limitExclusiveStart <= value && item.limitExclusiveEnd > value)
      } else {
        findItem = ruleList.find(item => {
          // this.formData.limitExclusiveEnd
          // this.formData.limitExclusiveStart
          // item.limitExclusiveStart <= value && item.limitExclusiveEnd > value
          return this.formData.limitExclusiveStart <= item.limitExclusiveStart && item.limitExclusiveStart <= value
        })
      }
      if (findItem) {
        callback(new Error('时间区间有重复'))
      }
      callback()
      // console.log(rule)
      // console.log(value)
      // console.log(this.ruleList)
    }
    const limitLenthVaild = (rule, value, callback) => {
      const regex = /^[1-9]\d+$/
      if (!regex.test(value)) {
        callback(new Error('限时抢单时长为大于10的整数！'))
      }
      callback()
    }
    return {
      visible: false,
      loading: false,

      dialogType: '',
      formData: {
        limitExclusiveStart: '',
        limitExclusiveEnd: '',
        limitExclusiveLength: '',
        isLimitQuote: false,
      },
      rules: {
        limitExclusiveStart: [
          { required: true, message: '请选择起始时间', trigger: 'blur' },
          // { validator: validateRange, trigger: 'blur' }
        ],
        limitExclusiveEnd: [
          { required: true, message: '请选择结束时间', trigger: 'blur' },
          // { validator: validateRange, trigger: 'blur' }
        ],
        limitExclusiveLength: [
          { required: true, message: '请输入时长', trigger: 'blur' },
          { validator: limitLenthVaild, trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    open(row) {
      if (row) {
        this.dialogType = 'edit'
      } else {
        this.dialogType = 'add'
      }
      this.formData = row ? JSON.parse(JSON.stringify(row)) : {
        limitExclusiveStart: '',
        limitExclusiveEnd: '',
        limitExclusiveLength: '',
        isLimitQuote: false,
      }
      this.visible = true
    },
    confirm() {
      const { limitExclusiveStart, limitExclusiveEnd, limitExclusiveLength } = this.formData
      // const regex = /^[1-9]\d+$/
      // if (!limitExclusiveStart || !limitExclusiveEnd) {
      //   return this.$message.error('请选择时间段！');
      // }
      // if (!regex.test(limitExclusiveLength)) {
      //   return this.$message.error('限时抢单时长为大于10的整数！');
      // }
      this.$refs['form'].validate((vaild) => {
        if (vaild) {
          if (limitExclusiveStart === limitExclusiveEnd) {
            this.$message.error('起始时间和结束时间相同！')
            return
          }
          this.$emit('confirm', this.dialogType, this.formData)
          this.visible = false
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.el-date-editor.el-input{
  width: 220px !important;
}
</style>