<template>
  <div>
    <el-dialog
      :title="'查看从账户（'+ row.merchantName + '）'"
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="800px"
      @closed="close"
    >
      <el-form ref="filterForm" :model="filterForm" inline size="small" class="el-form-group_isRound">
        <el-form-item label="回收商" prop="slaveMerchantId">
          <el-select v-model="filterForm.slaveMerchantId" clearable filterable placeholder="请输入回收商名称查询" >
            <el-option v-for="item in merchantList" :key="item.merchantId" :label="item.merchantName" :value="item.merchantId" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" round @click="getData()">查询</el-button>
        </el-form-item>
      </el-form>
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        isIndex
        :maxHeight="500"
        :columns="columns"
        :data="list"
        :currentPage="filterForm.pageNum"
        :pageSize="filterForm.pageSize"
        :total="total"
        @handleCurrentChange="getData"
        @handleSelectionChange="selectionChange"
        @handleSizeChange="handleSizeChange"
      >
        <el-table-column label="操作" slot="operation" align="center">
          <template slot-scope="{ row }">
            <el-button type="danger" size="mini" @click="unbind(row)">解除从账户</el-button>
          </template>
        </el-table-column>
      </GlobalTable>
    </el-dialog>
    <CommandDia ref="commandDia" />
  </div>
</template>

<script>
import _api from "@/utils/request";
import CommandDia from './CommandDia.vue';
export default {
  name: "SubAccountDia",
  components: { CommandDia },
  filters: {
    optionsFilter(v, options) {
      return options.find(item => item.value == v)?.label
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      row: {},
      filterForm: {
        pageNum: 1,
        pageSize: 10,
        slaveMerchantId: ''
      },
      total: 0,
      list: [],
      columns: [
        { label: "商家名称", prop: "merchantName" },
        { label: "联系人", prop: "contactName" },
        { label: "联系电话", prop: "contactPhone" },
        { slotName: "operation" },
      ],
      merchantList: [],
      selectList: []
    };
  },
  methods: {
    open(row) {
      this.row = row
      this.$refs['filterForm']?.resetFields()
      this.visible = true
      this.getData()
      this.getMerchantList()
    },
    getData(pageNum = 1) {
      this.loading = true
      _api.slaveAddedList({
        ...this.filterForm,
        masterMerchantId: this.row.merchantId,
        pageNum,
      }).then(res => {
        this.list = res.data.records
        this.filterForm.pageNum = res.data.current
        this.total = res.data.total
      }).finally(() => {
        this.loading = false
      })
    },
    getMerchantList() {
      _api.getMerchantSelectList().then(res => {
        this.merchantList = res.data
      })
    },
    handleSizeChange(num) {
      this.filterForm.pageSize = num
      this.getData()
    },
    selectionChange(v) {
      this.selectList = v.map(item => item.merchantId)
    },
    unbind(row) {
      this.$refs['commandDia'].open({
        title: '操作提醒',
        desc: `说明：移除后，该商户成交的订单，机款将不再从${this.row.merchantName}主账户中支出`,
        message: `是否确认将<span style="color:#ff8695">${row.merchantName}</span>从<span style="color:#3a9aff">${this.row.merchantName}</span>的从账户中移除？`,
        confirm: ({ command, close }) => {
          _api.unbindSlaveAccount({
            command,
            masterMerchantId: this.row.merchantId,
            slaveMerchantId: row.merchantId
          }).then(() => {
            close()
            this.$message.success('操作成功！')
            this.getData(this.filterForm.pageNum)
          })
        }
      })
    },
    close() {
      this.visible = false
      this.$emit('close')
    }
  }
};
</script>