<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" :width="options.width">
    <p v-if="options.desc" style="color: #ff8080;margin-bottom: 25px;" v-html="options.desc"></p>
    <div v-if="options.message" style="text-align: center;margin-bottom: 20px" v-html="options.message"></div>
    <div class="command">
      <div style="width: 85px;">动态口令：</div>
      <el-input v-model="command" placeholder="请输入谷歌动态口令"
        oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click.native="visible = false">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
const defaultOptions = {
  title: '提示',
  width: '500px',
  desc: '',
  message: '',
}
export default {
  data() {
    return {
      visible: false,
      command: "",
      options: defaultOptions,
    }
  },
  methods: {
    open(options){
      this.options = {
        ...JSON.parse(JSON.stringify(defaultOptions)),
        ...options,
      }
      this.command = ''
      this.visible = true
    },
    close () {
      this.visible = false
    },
    confirm() {
      if (!this.command) return this.$message.error('请输入动态口令')
      this.options.confirm && this.options.confirm({ command: this.command, close: this.close})
    }
  }
}
</script>

<style lang="scss" scoped>
.command {
  display: flex;
  align-items: center;
}

.command .el-input {
  width: 80%;
}
</style>