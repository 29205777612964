<template>
  <el-dialog :title="title" :visible.sync="visible" :close-on-click-modal="false" :width="width" @closed="closed">
    <p v-if="desc" style="color: #ff8080;margin-bottom: 25px;" v-html="desc"></p>
    <div v-if="message" style="text-align: center;margin-bottom: 20px" v-html="message"></div>
    <slot />
    <span slot="footer" class="dialog-footer">
      <el-button v-if="showCancelButton" size="small" @click="visible = false">{{ cancelButtonText }}</el-button>
      <el-button v-if="confirmButtonText" size="small" type="primary" @click="confrim">{{ confirmButtonText }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'MyMessageBox',
  data() {
    return {
      visible: false,
      title: '',
      width: '450px',
      desc: '',
      message: '',

      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: '取 消',
      confirmButtonText: '确 定',
      confirm: null,


    }
  },
  methods: {
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
    closed() {},
    confrim() {
      this.confirm && this.confirm()
      this.close()
    }
  }
}
</script>