var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":"添加规则","visible":_vm.visible,"close-on-click-modal":false,"width":"680px"},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"rules":_vm.rules,"label-width":"120px","label-position":"left"}},[_c('el-form-item',{attrs:{"label":"时间段：","prop":"limitExclusiveStart"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('el-form-item',{attrs:{"label-width":"0","prop":"limitExclusiveStart"}},[_c('el-time-select',{attrs:{"picker-options":{
              start: '00:00',
              step: '00:05',
              end: '24:00'
            },"placeholder":"起始时间"},model:{value:(_vm.formData.limitExclusiveStart),callback:function ($$v) {_vm.$set(_vm.formData, "limitExclusiveStart", $$v)},expression:"formData.limitExclusiveStart"}})],1),_c('div',{staticStyle:{"margin":"0 10px"}},[_vm._v("~")]),_c('el-form-item',{attrs:{"label-width":"0","prop":"limitExclusiveEnd"}},[_c('el-time-select',{attrs:{"picker-options":{
              start: _vm.formData.limitExclusiveStart,
              step: '00:05',
              end: '24:00'
            },"placeholder":"结束时间"},model:{value:(_vm.formData.limitExclusiveEnd),callback:function ($$v) {_vm.$set(_vm.formData, "limitExclusiveEnd", $$v)},expression:"formData.limitExclusiveEnd"}})],1)],1)]),_c('el-form-item',{attrs:{"label":"限时抢单时长：","prop":"limitExclusiveLength"}},[_c('el-input',{staticStyle:{"width":"440px"},attrs:{"placeholder":"请输入大于10的整数"},model:{value:(_vm.formData.limitExclusiveLength),callback:function ($$v) {_vm.$set(_vm.formData, "limitExclusiveLength", $$v)},expression:"formData.limitExclusiveLength"}}),_vm._v(" 分钟 ")],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.visible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.confirm}},[_vm._v("确 定")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }