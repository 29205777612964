<template>
  <el-dialog title="看主账户" :visible.sync="visible" :close-on-click-modal="false" width="400px">
    <p>主账户商家：{{info.merchantName}}</p>
    <p>关联从账户商家：{{info.slaveAccountNum}}个</p>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="visible = false">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
export default {
  data() {
    return {
      visible: false,
      row: {},
      info: {}
    }
  },
  methods: {
    open(row){
      this.row = row
      this.getData()
      this.visible = true
    },
    getData() {
      _api.masterInfo({
        merchantId: this.row.merchantId
      }).then(res => {
        this.info = res.data
        console.log(res)
      })
    },
    close () {
      this.visible = false
    },
  }
}
</script>

<style lang="scss" scoped>
.command {
  display: flex;
  align-items: center;
}

.command .el-input {
  width: 80%;
}
</style>